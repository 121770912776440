<template>
  <tr>
    <td>{{element.id}}</td>
    <td >
<!--      <i class="iconx-suspend text-danger mr-1" v-if="!element.active"></i>-->
<!--      <i class="iconx-unsuspend text-success mr-1" v-if="element.active"></i>-->
      <router-link
          :class="[!element.active ? 'text-danger' : 'text-green']"
          :to="{ name : 'users-edit' , params: { id: element.id } , query: { page: $route.params.page }}">
        {{ element.name }}
      </router-link>
    </td>
    <td>{{ element.email }}</td>
    <td>{{ elementRoles.join(', ') }}</td>
    <td class="text-center">
      <div class="list-icons">
        <div class="dropdown">
          <a href="#" class="list-icons-item dropdown-toggle caret-0" data-toggle="dropdown" aria-expanded="false">
            <i class="icon-more2 rotate-90"></i></a>
          <div class="dropdown-menu dropdown-menu-right" x-placement="bottom-end">
            <a href="javascript:void(0)" class="dropdown-item" @click="edit"><i class="icon-pencil3"></i> View/Edit</a>
            <a v-if="element.active && canSuspend" href="javascript:void(0)" @click="deactivate" class="dropdown-item">
              <i class="iconx-suspend text-danger"></i> Suspend </a>
            <a v-if="!element.active" href="javascript:void(0)" @click="activate" class="dropdown-item">
              <i class="iconx-unsuspend text-success"></i> Unsuspend </a>
            <div v-if="canDelete" class="dropdown-divider"></div>
            <a v-if="canDelete" href="javascript:void(0)" class="dropdown-item" @click="deleteElementConfirmation"><i
                class="iconx-delete text-danger"></i> Delete </a>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>

export default {
  components: {

  },
  props: {
    element: {
      type: Object,
      required: true
    },
    loading: {
      type: Object,
      default: function () {
        return {
          status: false
        }
      }
    }
  },
  computed: {
    filters: function() {
      return this.$store.getters['users/filters'];
    },
    hasValues() {
      return this.element.values.length > 0
    },
    elementRoles() {
      return this.element.roles.map(x => x.name)
    },
    canSuspend() {
      return this.element.id !== this.loggedUser.id;
    },
    canDelete() {
      return this.element.id !== this.loggedUser.id;
    },
  },
  methods: {

    activate: function () {
      this.saveStatus({
        id: this.element.id,
        status: true
      });
    },

    deactivate: function () {
      this.saveStatus({
        id: this.element.id,
        status: false
      });
    },

    saveStatus: function (data) {
      this.loading.status = true;
      this.$store.dispatch('users/status', data)
          .then((response) => {
            this.loading.status = false;
            this.$emit('element:updated');

            this.$swal({
              title: "Updated!",
              text: "User has been " + (data.status ? 'activated' : 'suspended') + ".",
              iconHtml: '<i class="iconx-verified"></i>',
              customClass: {
                confirmButton: 'btn btn-lg btn-success rounded-pill wmin-100'
              },
              icon: "success"
            });

          })
          .catch(error => {
            this.loading.status = false;
            this.errors = error.status.errors
          });
    },

    edit: function () {
      this.$router.push({name: 'users-edit', params: {id: this.element.id}, query: {page: this.$route.params.page}});
    },

    deleteElementConfirmation: function () {
      self = this;
      this.$swal({
        title: "Are you sure?",
        text: "You will not be able to recover this user!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete!",
        cancelButtonText: "No, cancel!",
        iconHtml: '<i class="iconx-alert"></i>',
        customClass: {
          confirmButton: 'btn btn-lg btn-success rounded-pill wmin-100',
          cancelButton: 'btn btn-lg btn-danger rounded-pill wmin-100 ml-3'
        },

      }).then((isConfirm) => {
        if (isConfirm.value) {
          self.deleteElement();
        }
      });
    },

    deleteElement: function () {
      this.loading.status = true;
      this.$store.dispatch('users/delete', {id: this.element.id})
          .then((response) => {
            this.loading.status = false;
            this.$emit('element:updated');
            if (response.status.success) {
              this.$swal({
                title: "Deleted!",
                text: "User has been deleted.",
                iconHtml: '<i class="iconx-verified"></i>',
                customClass: {
                  confirmButton: 'btn btn-lg btn-success rounded-pill wmin-100'
                },
                icon: "success"
              });
            }

          })
          .catch((error) => {
            this.loading.status = false;

            this.$store.dispatch('pnotify/alert', {
              text: error.status.message,
              addclass: 'bg-danger border-danger'
            });


          })
    }

  }
};
</script>
