<template>

    <!-- Page content -->
    <div class="page-content">
        <!-- Main content -->
        <div class="content-wrapper">

            <!-- Content area -->
            <div class="content d-flex justify-content-center align-items-center">
                <!-- Advanced login -->

                    <div class="login-form">
                        <div class="card-body">
                            <div class="text-center mb-3">
                                <div class="pl-2 pr-2 mb-3 mt-1">
                                    <img src="@assets/images/logo.png" style="max-width: 100%; display: block;" />
                                </div>
                                <h5 class="mb-0">Change Password</h5>
                                <span class="d-block text-muted">Enter your email address</span>
                            </div>
                            <div class="form-text text-danger" v-if="v$.form.token.$error || errors.token "></div>
                            <uiemail v-model="form.email" :v="v$.form.email" :isLoading="isLoading" :verr="errors.email || []"/>
                            <uipassword v-model="form.password" :v="v$.form.password" :isLoading="isLoading" :verr="errors.password || []" placeholder="New password" name="Password"/>
                            <uipassword v-model="form.password_confirmation" :v="v$.form.password_confirmation" :isLoading="isLoading" :verr="errors.password_confirmation || []" placeholder="Confirm new password" name="Password Confimation"/>

                            <div class="form-text text-danger" v-if="v$.form.token.$error">Change password token is invalid</div>
                            <div class="form-text text-danger pb-4" v-if="errors.token">{{errors.token[0]}}</div>
                            <div class="form-group">
                               <uibutton text="Change password" v-on:click="changepassword" :isLoading="isLoading" class="btn-block btn-lg">
                                    <i v-if='!isLoading' class="icon-arrow-right22 ml-3"></i>
                                    <i v-if='isLoading' class="icon-spinner2 spinner ml-3"></i>
                                </uibutton>
                            </div>
                            <div class=" text-center">
                                <span class="m-t-3 help-block">If you remembered your password, go to <router-link :to="{name : 'auth.login'}">Login</router-link> to access your account.</span>
                            </div>
                        </div>
                    </div>
                <!-- /advanced login -->

            </div>
            <!-- /content area -->

        </div>
        <!-- /main content -->

    </div>
    <!-- /page content -->

</template>
<script>

import { required, email, alphaNum } from '@vuelidate/validators'
import  ui  from '@components/ui'
import useVuelidate from "@vuelidate/core";

    export default {
        name: 'passwordresetform',
      setup () {
        return { v$: useVuelidate() }
      },
        components : {
            'uibutton' : ui.button,
            'uiemail' : ui.email,
            'uipassword' : ui.password
        },
        data () {
            return {
                isLoading : false,
                errors: [],
                form: {
                    token: "",
                    email: "",
                    password : "",
                    password_confirmation : ""
                },
            }
        },
        beforeMount: function () {
            if ( ! this.$route.query.hasOwnProperty('token')){
                this.$store.dispatch('pnotify/alert', {
                    text: 'Invalid change passework link',
                    addclass: 'bg-danger border-danger'
                }, {root:true});
                this.$router.push('/');
                return;
            }
            this.form.token = this.$route.query.token
        },
        watch: {
            '$route' (to, from) {
                // react to route changes...
            },
            email (value, old) {
                this.deleteObjProperty(this.errors, 'email')
            },
            password (value, old) {
                this.deleteObjProperty(this.errors, 'password')
            },
            password_confirmation (value, old) {
                this.deleteObjProperty(this.errors, 'password_confirmation')
            },
        },
        computed: {
            email() {
                return this.form.email
            },
            password() {
                return this.form.password
            },
            password_confirmation() {
                return this.form.password_confirmation
            },
        },
        validations: {
            form: {
                token: { required, alphaNum },
                email: { required, email },
                password: { required},
                password_confirmation: { required},
            }
        },
        methods: {
            changepassword () {
                this.v$.form.$touch();
                // if its still pending or an error is returned do not submit

                if (this.v$.form.$pending || this.v$.form.$error) {
                    this.$store.dispatch('pnotify/alert', {
                        text: 'Please check the errors.',
                        addclass: 'bg-danger border-danger'
                    }, {root:true});
                    return;
                }

                this.isLoading = true;
                this.errors = []

                this.$store.dispatch('auth/passwordchange', {
                    token: this.form.token,
                    email: this.form.email,
                    password: this.form.password,
                    password_confirmation: this.form.password_confirmation,
                })
                .then((response) => {
                    this.isLoading = false;
                    this.$router.push('/auth/login');
                })
                .catch( error => {
                    this.isLoading = false;
                    this.errors = error.status.errors
                });
            }
        }
    }
</script>
