<template>
  <validation class="form-group" :validator="v" :name="name" :inputclass="inputclass">
    <label v-if="label != ''">{{ label }} <sup v-if="isRequired" class="text-danger">*</sup></label>
    <select2 v-model="data" :isDisabled="isDisabled" :isLoading="isLoading" :placeholder="placeholder" :options="options" :allowClear="allowClear" :labelField="optionLabelField" :valueField="optionValueField"></select2>
    <div v-if="verr[0]" class="form-text errors text-danger">{{ verr[0] }}</div>
  </validation>
</template>

<script>
import select2 from "./helpers/select2.vue";

export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  components: {
    select2
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    options: {
      type: Array,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    inputclass: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    v: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    verr: {
      type: Array,
      default: []
    },
    allowClear: {
      type: Boolean,
      default: true
    },
    optionValueField: {
      type: String,
      default: 'id'
    },
    optionLabelField: {
      type: String,
      default: 'name'
    }
  },
  watch: {
    isLoading(value, old) {

    },
  },
  mounted() {
    this.initialize();
  },
  computed: {
    data: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.v.$touch();
        this.$emit("update:modelValue", value);
      }
    }
  },
  methods: {
    initialize() {
    },
  }
};
</script>
