<template>
  <input type="text" class="form-control bg-white" readonly v-model="mydate"/>
</template>
<script>
export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  components: {},
  props: {
    modelValue: {
      default: ''
    },
    minDate: {
      required: false,
      default: false
    },
    maxDate: {
      required: false,
      default: false
    }
  },
  watch: {
    modelValue(value, old) {
      if (value == '') value = moment()
      $(this.$el).data('daterangepicker').setStartDate(value)
      $(this.$el).data('daterangepicker').setEndDate(value)
    },
  },
  mounted() {
    var self = this;
    $(this.$el).daterangepicker({
      autoUpdateInput: false,
      singleDatePicker: true,
      showDropdowns: true,
      minDate: this.minDate ? this.minDate : moment().subtract(100, 'years'),
      maxDate: this.maxDate ? this.maxDate : moment().add(10, 'years')
    }).on('apply.daterangepicker', function (ev, picker) {
      self.mydate = picker.startDate.format('MM/DD/YYYY');
    });
    this.initialize();
  },
  computed: {
    mydate: {
      get: function () {
        return this.modelValue
      },
      set: function (value) {
        this.$emit("update:modelValue", value)
      }
    }
  },
  methods: {
    initialize() {
    },
    clear: function () {
      this.mydate = ''
    }
  }
};
</script>
