<template>
  <div class="card bg-transparent border-0" v-if="isLoaded">
    <div class="card-header bg-light pl-0 pr-0 border-0">

      <div class="page-header">
        <div class="page-header-content pl-0 pr-0 header-elements-inline">
          <div class="page-title w-100 pt-2 pb-2">
            <div class="row w-100">
              <div class="col-md-6">
                <h1 class="card-title">
                  <!--<a href="javascript:void(0)" @click="cancel" ><i class="icon-arrow-left52 mr-2"></i></a> -->

                  <span class="font-weight-semibold">{{form.first_name}} {{form.last_name}}</span>
                  <small class="d-block text-muted ml-0">{{roleLabels}}</small>
                </h1>
              </div>

            </div>
          </div>

          <div class="header-elements">
            <div>
            </div>
          </div>
        </div>
      </div>

    </div>



    <div class="card-body bg-white rounded pt-4 pb-4 shadow shadow-1 shadow-sm border-0">
      <div class="row">
        <div class="col-md-6 pl-4 pr-4">

          <h4>User Details</h4>

          <uiformgrouptext :isRequired="true" v-model="v$.form.email.$model" :v="v$.form.email" :isLoading="isLoading"
                           inputclass="light-gray-bg"
                           @update:modelValue="clearError('email')"
                           name="email" label="Email" :verr="errors.email || []"/>


          <uiformgrouptext :isRequired="true" v-model="v$.form.first_name.$model" :v="v$.form.first_name"
                           @update:modelValue="clearError('first_name')"
                           :isLoading="isLoading" name="first_name" label="First Name" inputclass="light-gray-bg"
                           :verr="errors.first_name || []"/>

          <uiformgrouptext :isRequired="true" v-model="v$.form.last_name.$model" :v="v$.form.last_name"
                           @update:modelValue="clearError('last_name')"
                           :isLoading="isLoading" name="last_name" label="Last Name" inputclass="light-gray-bg"
                           :verr="errors.last_name || []"/>
        </div>
        <div class="col-md-6 pl-4 pr-4">

          <h4>Roles</h4>

          <div class="form-group">
            <label class="mb-2"><a href="javascript:void(0)" @click="selectAllRoles">Select
              all</a></label> <!--  | <a href="javascript:void(0)" @click="deselectAllRoles">Deselect all</a> -->
            <div class="p-3 light-gray-bg">
              <div class="row">
                <div class="col-md-12 my-1" v-for="role in misc.roles" :key="role.id">
                  <uiformswitch v-model="form.roles[role.id].status" :isLoading="isLoading" name="roles"
                                :disabled="disabledRole(role.id)"
                                :size="'large'"
                                @update:modelValue="clearError('roles')"
                                :label="role.name" class="mb-2" :verr="[]"/>
                </div>
              </div>
            </div>
            <div class="errors form-text" v-if="errors.roles && errors.roles.length > 0">
              <div class="text-danger">{{ errors.roles[0] }}</div>
            </div>
          </div>

        </div>
      </div>

    </div>

    <div class="clearfix mb-5" ></div>

    <changepassword :element-data="elementData"/>

    <div class="card-body bg-light footer-buttons">
      <uibutton text="Save" v-on:click="save" :isLoading="isLoading" :useClass="saveBtnClass" class="btn-lg rounded-pill text-center px-4"></uibutton>
      <a href="javascript:void(0)" @click="cancel" class="ml-3 text-default">Cancel</a>
    </div>
  </div>
  <div v-else>
    <div style="height: 100px">
      <div class="loader loader-inline no-margin-top mt-5"></div>
    </div>
  </div>
</template>
<script>
import {required, alphaNum, requiredIf, email, string, minLength} from '@vuelidate/validators'
import ui from '@components/ui'
import useVuelidate from "@vuelidate/core";
import { ROLE_ADMIN } from "@base/constants";
import {customRequired} from "@apphelpers/index";
import changepassword from '@components/pages/users/changepassword.vue'

export default {
  name: 'UserEdit',
  setup() {
    return {v$: useVuelidate()}
  },
  components: {
    'uiformgrouptext': ui.formgrouptext,
    'uiformgrouptextarea': ui.formgrouptextarea,
    'uiformgroupWYSIWYG': ui.formgroupWYSIWYG,
    'uiformgroupswitch': ui.formgroupdoubleswitch,
    'uiformgroupselect2': ui.formgroupselect2,
    'uiformgroupprice': ui.formgroupprice,
    'uiformswitch': ui.formswitch,
    'uibutton': ui.button,
    'uiformgroupdatepicker': ui.formgroupdatepicker,
    'uiformgrouptextphone': ui.formgrouptextphone,
    changepassword
  },
  props: {
    elementData: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      element: null,
      elementReady: false,
      formTouched: false,
      trackChanges: false,
      form: {
        first_name: "",
        last_name: "",
        roles: {},
        email: "",
      },
      misc: null,
      errors: {},
      isLoading: false
    }
  },
  watch: {
    element(value) {
      if (value !== null) this.initializeElement();
    },
    misc() {
    },
    isLoaded() {
    },
    roles: {
      deep:true,
      handler(value, old) {
        if (this.trackChanges) {
          let newHasAdmin = value.filter((role) => {
            return role.id === ROLE_ADMIN
          }).length > 0;
          let oldHasAdmin = old.filter((role) => {
            return role.id === ROLE_ADMIN
          }).length > 0

          if (newHasAdmin && !oldHasAdmin) {
            this.$swal({
              title: "Are you sure you want to make this person an admin?",
              text: "",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes!",
              cancelButtonText: "No, cancel!",
              iconHtml: '<i class="iconx-alert"></i>',
              customClass: {
                confirmButton: 'btn btn-lg btn-success rounded-pill wmin-100',
                cancelButton: 'btn btn-lg btn-danger rounded-pill wmin-100 ml-3'
              },

            }).then((isConfirm) => {
              if (!isConfirm.value) {
                this.form.roles[ROLE_ADMIN].status = false;
              }

            });
          }


        }
      }
    },
    form: {
      handler() {
        if (this.trackChanges) {
          this.formTouched = true;
          this.v$.$touch();
          this.$emit('form:touched');
        }
      },
      deep: true
    }
  },
  mounted() {
    this.initialize();
  },
  computed: {
    userRoles() {
      if (!this.loggedUser) return [];
      return this.loggedUser.roles.map(p => p.name)
    },
    isLoaded() {
      return this.misc != null && this.element != null && this.elementReady
    },
    roleLabels() {
      return this.misc.roles.filter((role) => {
        return this.form.roles[role.id] && this.form.roles[role.id].status
      }).map((role) => role.name).join(', ')
    },
    saveBtnClass() {
      if (!this.elementReady) return 'btn-outline-secondary';
      if (!this.formTouched) {
        return 'btn-outline-secondary'
      }
      return 'btn-primary';
    },
    roles() {
      if (!this.misc || !this.misc.roles) return [];
      return this.misc.roles.filter(function (c) {
        return this.form.roles[c.id].status === true;
      }, this);
    }
  },
  validations() {
    return {
      form: {
        first_name: {required: customRequired(required)},
        last_name: {required: customRequired(required)},
        roles: {required: customRequired(required)},
        email: {required: customRequired(required), email},

      }
    }
  },
  methods: {
    clearError: function(field) {
      this.deleteObjProperty(this.errors, field);
    },
    selectAllRoles: function () {
      this.misc.roles.forEach(function (c) {
        this.form.roles[c.id].status = true;
      }, this);
    },
    deselectAllRoles: function () {
      this.misc.roles.forEach(function (c) {
        this.form.roles[c.id].status = false;
      }, this);
    },
    initializeRoles() {
      this.misc.roles.forEach(function (c) {
        if (!this.form.roles.hasOwnProperty(c.id)) {
          this.form.roles[c.id] = {
            status: false,
            id: c.id
          }
        }
      }, this);

      setTimeout(() => {
        this.trackChanges = true;
        this.v$.$reset();
      })
    },

    disabledRole(roleID) {
      if (roleID === ROLE_ADMIN) {
        if (this.element.id !== this.loggedUser.id) return false;
        return true;
      }
      return false;
    },

    initialize() {
      this.element = this.elementData;
      this.loadMisc();
      this.initializeElement();
    },
    initializeElement: function () {
      this.form.first_name = this.element.first_name;
      this.form.last_name = this.element.last_name;
      this.form.email = this.element.email;
      this.form.phone = this.element.phone;
      this.form.zip = this.element.zip;
      this.form.people_at_home = this.element.people_at_home;
      this.form.country_id = this.element.country ? this.element.country.id : '';
      this.form.income_level_id = this.element.income_level ? this.element.income_level.id : '';
      this.form.education_level_id = this.element.education_level ? this.element.education_level.id : '';
      this.form.hear_source_id = this.element.hear_source ? this.element.hear_source.id : '';

      this.element.roles.forEach(function (c) {
        this.form.roles[c.id] = {
          status: true,
          id: c.id
        };
      }, this);

      this.elementReady = true;
    },
    loadMisc: function () {
      this.$store.dispatch('users/misc')
          .then((response) => {
            this.misc = response.data;
            this.initializeRoles();
          })
    },
    cancel: function () {
      this.$router.push({name: 'users-view-page', params: {page: this.$route.query.page ? this.$route.query.page : 1}});
    },
    save() {
      this.v$.form.$touch();
      // if its still pending or an error is returned do not submit
      if (this.v$.form.$pending || this.v$.form.$error) {
        this.$store.dispatch('pnotify/alert', {
          text: 'Please check the errors.',
          addclass: 'bg-danger border-danger'
        }, {root: true});
        return;
      }

      this.isLoading = true;
      this.errors = {}

      this.$store.dispatch('users/update', {...{id: this.element.id}, ...this.form})
          .then((response) => {
            this.isLoading = false;
            this.element = response.data;
            this.initializeElement();

            setTimeout(() => {
              this.formTouched = false;
              this.$emit('form:reset');
              this.v$.$reset();
            })
            /*
            this.$router.push({
              name: 'users-view-page',
              params: {page: this.$route.query.page ? this.$route.query.page : 1}
            });
             */
          })
          .catch(error => {
            this.isLoading = false;
            this.errors = error.status.errors
          });
    },

  }
}
</script>
