<template>
  <div class="home-dashboard card bg-transparent border-0" v-if="isLoaded">

    <div class="card-header bg-light pl-0 pr-0 border-0">
      <h1 class="card-title mt-3">Welcome {{loggedUser.first_name}}!</h1>
    </div>

    <div class="row mt-2">
      <div class="col-xl-6 pr-xl-3 pt-3 pb-3" style="min-height: 430px">

      </div>

      <div class="col-xl-6 pl-xl-3 pt-3 pb-3" style="min-height: 430px">

      </div>
    </div>

    <div class="row">
      <div class="col-xl-6 pr-xl-3 pt-3" style="min-height: 430px">


      </div>

      <div class="col-xl-6 pl-xl-3 pt-3" style="min-height: 430px">

      </div>
    </div>

  </div>

</template>
<script>

import ui from '@components/ui'

export default {
  name: 'Dashboard',
  props: {
    data: {
      type: Object,
      required: true
    },
    loading: {
      type: Object,
      default: function () {
        return {status: false}
      }
    }
  },
  components: {
  },

  data() {
    return {
      orders: {},

      errors: {},
      isLoaded: false,
      isLoading: false,
    }
  },
  watch: {
    data(value, old) {
      this.initialize()
    },
    loading(value, old) {
    },
  },

  mounted() {
    this.initialize();
  },
  computed: {},

  methods: {
    initialize() {
      if (!this.hasAdminRole) {
        if (this.hasHeinzRole) {
          this.$router.push({name: 'heinz-view-page', params: {page: 1}});
        }
      }
      this.isLoaded = true;
    },

    elementsUpdated: function () {
      this.$emit('element:updated')
    },
  }
}
</script>
