/**
 * Import Dependency
 */

/**
 * Import API
 */

/**
 * Declare Variable
 */

/**
 * Export
 */
const state = {
	hasSidebar : false,
	sidebarCollapsed: false,
	per_page : 10
}

const getters = {
	hasSidebar : state => {
		return state.hasSidebar
	},
	sidebarCollapsed : state => {
		return state.sidebarCollapsed
	},
	per_page : state => {
		return state.per_page
	},
	per_page_options : state => {
		return [
			{ id : 5, name : '5' },
			{ id : 10, name : '10' },
			{ id : 20, name : '20' },
			{ id : 50, name : '50' },
			{ id : 100, name : '100' }
		];
	}
}

const mutations = {
	setSidebar(state) {
		state.hasSidebar = true
	},
	removeSidebar(state) {
		state.hasSidebar = false
	},
	per_page(state, opt) {
		state.per_page = opt
	},
	toggleSidebar(state) {
		state.sidebarCollapsed = !state.sidebarCollapsed
	},
}

const actions = {
	setSidebar({ commit, dispatch }) {
		commit('setSidebar')
	},
	removeSidebar({ commit, dispatch }) {
		commit('removeSidebar')
	},
	toggleSidebar({ commit, dispatch }) {
		console.log('toggle');
		commit('toggleSidebar')
	},
	per_page({ commit, dispatch }, opt) {
		commit('per_page', opt)
	},

	download({ commit, dispatch }, rsp) {

		var blob = new Blob([rsp.data], { type: rsp.type });
	    if (navigator.msSaveBlob) { // IE 10+
	        navigator.msSaveBlob(blob, 'download.csv');
	    } else {
	        var link = document.createElement("a");
	        if (link.download !== undefined) { // feature detection
	            // Browsers that support HTML5 download attribute
	            var url = URL.createObjectURL(blob);
	            link.setAttribute("href", url);
	            link.setAttribute("download", rsp.name);
	            link.style.visibility = 'hidden';
	            document.body.appendChild(link);
	            link.click();
	            document.body.removeChild(link);
	        }
	    }

		//const url = window.URL.createObjectURL(new Blob([rsp.data, {type: 'text/plain'}]));
        //const link = document.createElement('a');
        //link.href = url;
        //link.setAttribute('download', 'download.csv');
        //document.body.appendChild(link);
        //link.click();
        //link.remove();
	},
}



/**
 * Export
 */
export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
