import Main from './main';
import Admin from './AdminPage.vue';
import PageContent from './PageContent.vue';


export default {
	Admin,
	Main,
	PageContent,
}
