import button from './button.vue'
import email from './email.vue'
import password from './password.vue'
import search from './search.vue'
import inputdate from './inputdate.vue'
import select2 from './select2.vue'
import multipleselect2 from './multipleselect2.vue'
import formgrouptext from './formgrouptext.vue'
import formgrouppassword from './formgrouppassword.vue'
import formgroupprice from './formgroupprice.vue'

import formgrouptextphone from './formgrouptextphone.vue'
import formgroupnumbers from './formgroupnumbers.vue'
import formgroupdecimal from './formgroupdecimal.vue'

import formgrouptextarea from './formgrouptextarea.vue'
import formgroupdate from './formgroupdate.vue'
import formgrouptime from './formgrouptime.vue'
import formgroupupload from './formgroupupload.vue'
import formgroupimageupload from './formgroupimageupload.vue'
import formgroupswitch from './formgroupswitch'
import formgroupswitcheslist from './formgroupswitcheslist'
import formgroupdoubleswitch from './formgroupdoubleswitch'

import formgroupWYSIWYG from './formgroupWYSIWYG.vue'
import formgroupselect2 from './formgroupselect2.vue'
import daterangepicker from './daterangepicker/index.vue'
import checkboxuniform from './checkboxuniform'
import radiouniform from './radiouniform'
import formswitch from './switch.vue'
import formgroupdatepicker from './formgroupdatepicker.vue'

export default {
	button,
	email,
	password,
	search,
	inputdate,
	select2,
	multipleselect2,
	formgrouptext,
	formgrouppassword,
	formgroupprice,
	formgrouptextphone,
	formgroupnumbers,
	formgroupdecimal,
	formgrouptextarea,
	formgroupdate,
	formgrouptime,
	formgroupupload,
	formgroupimageupload,
	formgroupWYSIWYG,
	formgroupselect2,
	formgroupswitch,
	formgroupswitcheslist,
	formgroupdoubleswitch,
	daterangepicker,
	checkboxuniform,
	radiouniform,
	formswitch,
	formgroupdatepicker
}
