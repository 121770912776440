<template>

</template>
<script>

export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  props: {
    modelValue: {
      required: true
    },
    name: {
      required: true
    },
    data: {
      required: true
    }
  },
  data() {
    return {
      valueData: '',
      loaded: false
    }
  },
  watch: {
    valueData: function (value) {
      //console.log('fac update in radio', value);
      this.$emit("update:modelValue", value)
    },
    data: function (value) {
    }
  },
  mounted() {
    this.initialize()
  },
  computed: {
    isLoaded() {
      return this.loaded
    }
  },
  methods: {
    initialize: function () {
      this.valueData = this.modelValue;
      this.loaded = true;
      //$(this.$el).uniform();
    }
  }
}
</script>
