<template>
  <button class="btn btn-light border-gray" type="button"><i class="icon-calendar5"></i></button>
</template>
<script>
export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  components: {},
  props: {
    modelValue: {
      default: function () {
        return {
          from: '',
          to: ''
        }
      }
    }
  },
  watch: {
    modelValue(value, old) {

    },
    from(value) {

      $(this.$el).data('daterangepicker').setStartDate(value)
    },
    to(value) {
      if (value == '') value = moment()
      $(this.$el).data('daterangepicker').setEndDate(value)
    }
  },
  mounted() {
    var self = this;
    $(this.$el).daterangepicker(
        {
          startDate: self.from,
          endDate: self.to,
          minDate: '01/01/2015',
          autoUpdateInput: false,
          showDropdowns: true,
          ranges: {
            'Today': [moment(), moment()],
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
          },
          opens: $('html').attr('dir') == 'rtl' ? 'right' : 'left',
          applyClass: 'btn-sm bg-slate-600 btn-block',
          cancelClass: 'btn-sm btn-light btn-block',
          locale: {
            format: 'MM/DD/YYYY',
            direction: $('html').attr('dir') == 'rtl' ? 'rtl' : 'ltr'
          }
        },
        //function(start, end) {
        //    self.from = start.format('MM/DD/YYYY')
        //    self.to = end.format('MM/DD/YYYY')
        //}
    ).on('apply.daterangepicker', function (ev, picker) {
      self.from = picker.startDate.format('MM/DD/YYYY')
      self.to = picker.endDate.format('MM/DD/YYYY')
    });
    this.initialize();
  },
  computed: {
    from: {
      get() {
        return (this.modelValue.from == '') ? moment().startOf('year') : this.modelValue.from
        //return this.modelValue.from;
      },
      set(value) {
        this.modelValue.from = value;
        this.rangechanged();
      }
    },
    to: {
      get() {
        return (this.modelValue.to == '') ? moment() : this.modelValue.to
      },
      set(value) {
        this.modelValue.to = value;
        this.rangechanged();
      }
    },
  },
  methods: {
    initialize() {
    },
    rangechanged: function () {
      this.$emit("update:modelValue", this.modelValue)
    },
  }
};
</script>
