<template>
  <validation class="form-group" :validator="v" :name="name" :inputclass="inputclass">
    <label v-if="label != ''">{{ label }} <sup v-if="isRequired" class="text-danger">*</sup></label>

    <div class="flex w-full h-screen items-center justify-center text-center" >
      <div class="p-3 border border-gray-300" @dragover="dragover" @dragleave="dragleave" @drop="drop">
        <input type="file" name="fields[assetsFieldHandle][]" :id="'upload_'+name"
               class="w-px h-px opacity-0 overflow-hidden position-absolute" @change="onChange" ref="file" accept=".jpg,.jpeg,.png,.gif" />

        <label :for="'upload_'+name" class="block cursor-pointer mb-0">
          <div>
            Click to upload or drag files here.
          </div>
        </label>
        <div class="mt-2 text-left" v-if="selectedFile || previous" v-cloak>
          <div v-if="previous && !selectedFile">
            <div class="flex">
              <a :href="previous" target="_blank"><img :src="previous" style="height: 60px;" /></a>
              <a class="text-danger cursor-pointer ml-4" @click="removePrevious"><i class="iconx-delete"></i></a>
            </div>
          </div>
          <div class="text-sm p-1 position-relative" v-if="selectedFile">
            <div class="flex">
              <img v-if="uploadPreview" :src="uploadPreview" style="height: 60px;" />
              <a class="text-danger cursor-pointer ml-4" @click="remove()"><i class="iconx-delete"></i></a>
            </div>
          </div>
        </div>
        <div class="p-1" v-if="errors.length">
          <div class="text-danger text-sm" v-for="error in errors">{{error}}</div>
        </div>
      </div>
    </div>

    <div v-if="verr[0]" class="form-text text-danger">{{ verr[0] }}</div>
  </validation>
</template>

<script>

import FileUpload from 'vue-upload-component'

export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  components: {
    FileUpload
  },
  props: {
    modelValue: {
      type: [String, Boolean, File],
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    inputclass: {
      type: String,
      default: ""
    },
    instructions: {
      type: String,
      default: ''
    },
    v: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    previous: {
      type: String,
      default: ''
    },
    verr: {
      type: Array,
      default: []
    },
    maxSize: {
      type: Number,
      default: Infinity
    },
    minWidth: {
      type: Number,
      default: 0
    },
    maxWidth: {
      type: Number,
      default: Infinity
    },
    minHeight: {
      type: Number,
      default: 0
    },
    maxHeight: {
      type: Number,
      default: Infinity
    },
    minAspectRatio: {
      type: [Number, String],
      default: null
    },
    maxAspectRatio: {
      type: Number,
      default: null
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.v.$touch();
        this.$emit("update:modelValue", value);
      }
    },
    previousFileName() {
      return this.previous;
    },
    uploadPreview() {
      if (!this.selectedFile) return null;
      let _URL = window.URL || window.webkitURL;
      let objectUrl = _URL.createObjectURL(this.selectedFile);
      return objectUrl;
    }
  },
  data() {
    return {
      selectedFile: null,
      errors: []
    }
  },
  watch: {
    modelValue(value) {
      if (!value) {
        this.filelist = [];
      }
    },
    selectedFile: {
      deep: true,
      handler(value) {
        if (value ) {
          this.value = value;
        }
        else {
          this.value = null;
        }
      }
    },
    errors: {
      deep: true,
      handler(value) {
        if (value && value.length) {
          this.value = null;
        }
        this.$emit('image:error', value);
      }
    }
  },
  methods: {
    removePrevious: function () {
      this.value = null;
      this.$emit('previous:delete');
    },

    onChange() {


      let selectedFile = this.$refs.file.files[0];

      if (selectedFile) {

        this.errors = [];

        let sizeInMb = selectedFile.size / 1024 / 1024;

        if (selectedFile.type.indexOf('image/') !== 0) {
          this.errors.push("Invalid image. " + selectedFile.type + " file type is not accepted.");
          return;
        }

        if (sizeInMb > this.maxSize) {
          this.errors.push("The selected image exceeds the maximum size of " + this.maxSize + "MB.");
        }

        let img = new Image();
        let _URL = window.URL || window.webkitURL;
        let objectUrl = _URL.createObjectURL(selectedFile);
        var that = this;
        img.onload = function() {
          if (this.width < that.minWidth) {
            that.errors.push("The minimum accepted width is " + that.minWidth + "px.");
          }
          if (this.width > that.maxWidth) {
            that.errors.push("The maximum accepted width is " + that.maxWidth + "px.");
          }
          if (this.height < that.minHeight) {
            that.errors.push("The minimum accepted height is " + that.minHeight + "px.");
          }
          if (this.height > that.maxHeight) {
            that.errors.push("The maximum accepted height is " + that.maxHeight + "px.");
          }

          let aspectRatio = this.width / this.height;

          if (that.minAspectRatio && aspectRatio < eval(that.minAspectRatio)) {
            that.errors.push("The minimum accepted aspect ratio is" + that.minAspectRatio + ".");
          }
          if (that.maxAspectRatio && aspectRatio > eval(that.maxAspectRatio)) {
            that.errors.push("The maximum accepted aspect ratio is" + that.maxAspectRatio + ".");
          }
          _URL.revokeObjectURL(objectUrl);
        };
        img.src = objectUrl;

      }

      this.selectedFile = selectedFile;

    },
    remove() {
      this.selectedFile = null;
      this.errors = [];
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('border-success')) {
        event.currentTarget.classList.remove('border-gray-300');
        event.currentTarget.classList.add('border-success');
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add('border-gray-300');
      event.currentTarget.classList.remove('border-success');
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add('border-gray-300');
      event.currentTarget.classList.remove('border-success');
    }

  }
};
</script>
