<template>
  <cardHeader title="Accounts" class="bg-transparent header-elements-sm-inline pr-0 pl-0 pt-4 pb-4 border-0">
    <div class="header-elements ">
      <router-link :to="{ name : 'users-add'}" active-class="active" class="btn btn-primary rounded-pill">
        <b><i class="icon-plus22"></i></b> New Account
      </router-link>
    </div>
  </cardHeader>
</template>
<script>

import uiCard from '@components/layout/card'
import uibutton  from '@components/ui/button.vue'

export default {
	components : {
		'cardHeader' : uiCard.cardHeader,
		uibutton
	},
    props: {
		
    },
    watch: {
    },
    data() {
		return {
			isExporting : false
		}
	},

	mounted () {
    },
    computed: {
		filters : function(){
            return this.$store.getters['users/filters']
        },

        options : function(){
            return this.$store.getters['users/options']
        },
		data() {
			return {
				options : this.options,
				filters : this.filters
			}
		}
    },
    methods : {

    }
};
</script>
