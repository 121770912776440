<template>
  <div>
    <div class="row">
      <div class="col-md-4">

        <div class="form-group">
          <!-- <label>Search</label> -->
          <div class="input-group">
            <input type="text" :disabled='isLoading' @keyup="search" v-model.trim="data" class="form-control "
                   v-bind:class="[ (hasSearchString) ? 'border-right-0' : '']" :placeholder="placeholder">
            <span v-if="hasSearchString" class="input-group-append">
                            <button class="btn btn-white border border-left-0" type="button" @click="clear"><i
                                class="icon-cross"></i></button>
                        </span>
            <span v-if="allowAdvanced" class="input-group-append">
                            <button class="btn btn-white border border-gray border-left-0" type="button"
                                    @click="showFields = !showFields"><i
                                v-bind:class="[ (showAdvanced) ? 'text-primary' : '' , (isAdvancedFilled) ? 'icon-filter3' : 'icon-filter4' ]"></i></button>
                        </span>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <select2 v-model="role" :options="roles" label="" placeholder="Role"/>
      </div>
      <div class="col-md-2">
        <select2 v-model="status" :options="statuses" label="" placeholder="Status"/>
      </div>
    </div>
    <slot v-if="showAdvanced"></slot>
  </div>
  <!--
  <div class="form-group" :inputclass="inputclass">
      <label v-if="label != ''">{{label}}</label>
      <div class="input-group">
          <input type="text" :disabled='isLoading' @keyup="search" v-model.trim="data" class="form-control " v-bind:class="[ (hasSearchString) ? 'border-right-0' : '']" :placeholder="placeholder">
          <span v-if="hasSearchString" class="input-group-append">
              <button class="btn btn-white border border-left-0" type="button" @click="clear"><i class="icon-cross"></i></button>
          </span>
          <span v-if="allowAdvanced" class="input-group-append">
              <button class="btn btn-white border border-left-0" type="button" @click="clear"><i class="icon-filter4"></i></button>
          </span>
      </div>
  </div>
  -->
</template>

<script>
import ui from '@components/ui'

export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  components: {
    'select2': ui.select2,
  },
  props: {
    modelValue: {
      type: String,
      default: ""
    },
    statuses: {
      type: Array,
      required: true
    },
    roles: {
      type: Array,
      required: true
    },
    filters: {
      type: Object,
      required: true
    },
    inputclass: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    allowAdvanced: {
      type: Boolean,
      default: true
    },
    isAdvancedFilled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    modelValue(v) {
      this.data = v
    },
    data() {

    },
    isAdvancedFilled() {
    },
    filters() {
    },
    statuses: {
      handler() {

      },
      deep: true
    }
  },
  data() {
    return {
      timeoutSearch: null,
      data: this.modelValue,
      showFields: false
    }
  },
  computed: {
    showAdvanced: function () {
      return this.allowAdvanced && this.showFields
    },
    hasSearchString: function () {
      return !(this.data == '' || this.data == null)
    },
    status: {
      get: function () {
        return this.filters.status;
      },
      set: function (value) {
        this.statusChanged(value)
      }
    },
    role: {
      get: function () {
        return this.filters.role;
      },
      set: function (value) {
        this.roleChanged(value)
      }
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize: function () {
      this.data = this.modelValue;
    },
    search: function () {
      clearTimeout(this.timeoutSearch);
      this.timeoutSearch = setTimeout(function () {
        this.$emit("update:modelValue", this.data);
      }.bind(this), 400);
    },
    clear: function () {
      this.data = '';
      this.search();
    },
    statusChanged: function (value) {
      this.$emit('status:changed', value)
    },
    roleChanged: function (value) {
      this.$emit('role:changed', value)
    },
  }

};
</script>
