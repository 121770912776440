/**
 * Import Dependency
 */
import axios from '../../interceptor'

/**
 * Declare Variable
 */
const baseUrl = process.env.VUE_APP_API_URL


const getUserUrl = `${baseUrl}/admin/account`
const passwordResetUrl = `${baseUrl}/admin/forgot/password`
const passwordChangeUrl = `${baseUrl}/admin/password/change`
const signinUrl = `${baseUrl}/admin/login`
const signoutUrl = `${baseUrl}/logout`

const refreshTokenUrl = `${baseUrl}/token/refresh`

/**
 * Export
 */
export default {
	signin(data) {
		return axios.post(signinUrl, data)
	},
	passwordreset(data) {
		return axios.post(passwordResetUrl, data)
	},
	passwordchange(data) {
		return axios.post(passwordChangeUrl, data)
	},
	me(data) {
		return axios.get(getUserUrl, data)
	},
	signout(data) {
		return axios.post(signoutUrl, {
			'username': data.username
		})
	},
	refreshToken(data) {
		const accessToken = data
		return axios.get(`${refreshTokenUrl}`, {
			params: {
				'accessToken': accessToken
			}
		})
	}
}
