<template>
  <validation class="form-group-feedback form-group-feedback-left" :validator="v" name="email">
    <input type="text" :disabled='isLoading' v-model.trim="email" class="form-control"
           v-bind:class="[ (v.$error || verr[0]) ? 'border-danger' : '' ]" placeholder="Email">
    <div class="form-control-feedback">
      <i class="icon-mention text-muted"></i>
    </div>
    <div v-if="verr[0]" class="form-text text-danger">{{ verr[0] }}</div>
  </validation>
</template>

<script>

export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  props: {
    modelValue: {
      type: String,
      default: ""
    },
    v: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    verr: {
      type: Array,
      default: []
    }
  },
  computed: {
    email: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.v.$touch();
        this.$emit("update:modelValue", value);
      }
    }
  }
};
</script>
