<template>
  <div class="content-wrapper">
    <pageheader title="Dashboard"></pageheader>
    <div class="content">
      <div v-if="isLoaded" class="row">
        <div class="col-md-12">
          <uidashboard :data="data" @element:updated="elementsUpdated" :loading="loading"/>
        </div>
      </div>
      <div v-else>
        <div style="height: 100px">
          <div class="loader loader-inline mt-5"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pageheader from '@components/layout/header'
import uiDashboard from '@components/pages/dashboard'

export default {
  name: 'Dashboard',
  components: {
    pageheader,
    'uidashboard': uiDashboard.view
  },
  data() {
    return {
      isPermissionsChecked: false,
      isLoaded: true,
      data: {},
      loading: {
        status: false,
        message: 'Loading ...',
      },
    }
  },
  watch: {
    isPermissionsChecked(newValue, oldValue) {
      if (newValue) this.initialize;
    }
  },
  mounted() {
    this.initialize();
  },
  computed: {
    userPermissions() {
      if (!this.loggedUser) return [];
      return this.loggedUser.permissions.map(p => p.name)
    },
  },
  methods: {
    initialize() {
      //this.loadConfig();
    },
    elementsUpdated: function () {
      this.loadConfig();
    },
    loadConfig: function () {
      this.loading.status = true;
      this.$store.dispatch('dashboard/get')
          .then((response) => {
            this.data = response.data;
            this.isLoaded = true;
            this.loading.status = false;
          })
          .catch(error => {
          });
    }
  }
}
</script>
