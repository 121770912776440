<template>
  <input type="checkbox" v-model="valueData" :value="data" class="form-check-input-styled" :name="name" data-fouc>
</template>
<script>

export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    name: {
      required: true
    },
    data: {
      required: true
    }
  },
  data() {
    return {
      valueData: false,
      loaded: false
    }
  },
  watch: {
    valueData: function (value) {
      this.$emit("update:modelValue", value)
    },
    modelValue: function (value) {
      this.valueData = value;
      $.uniform.update(this.$el);
    }
  },
  mounted() {
    this.initialize()
  },
  computed: {
    isLoaded() {
      return this.loaded
    }
  },
  methods: {
    initialize: function () {
      this.valueData = this.modelValue;
      this.loaded = true;
      $(this.$el).uniform();
    }
  }
}
</script>
