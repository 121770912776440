<template>
  <div class="form-check">
    <label class="form-check-label">
      <checkbox v-if="isLoaded" v-model="valueData" :data="data" :name="name"/>
      {{ label }}
    </label>
  </div>
</template>
<script>
import checkbox from './checkbox.vue'

export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  components: {
    checkbox
  },
  props: {
    modelValue: {
      required: true
    },
    name: {
      required: true
    },
    data: {
      type: [String, Number],
      default: ""
    },
    label: {
      type: String,
      default: ""
    }
  },
  watch: {
    valueData: function (value) {
      this.$emit("update:modelValue", value)
    },
    modelValue: function (value) {
      this.valueData = value;
    }
  },
  data() {
    return {
      valueData: '',
      loaded: false
    }
  },

  mounted() {
    this.initialize()
  },
  computed: {
    isLoaded() {
      return this.loaded
    }
  },
  methods: {
    initialize: function () {
      this.valueData = this.modelValue;
      this.loaded = true;
    },
  }
}
</script>
