<template>
  <div class="form-group" :class="{ error: hasErrors }">
    <slot/>
    <div class="errors form-text" v-if="hasErrors">
      <div class="text-danger" v-for="error in activeErrorMessages">{{ error.$message }}</div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      validator: {
        type: Object,
        required: true
      }
    },
    computed: {
      hasErrors() {
        return this.validator.$errors.length;
      },
      activeErrorMessages() {
        return this.validator.$errors
      }
    }
  }
</script>
