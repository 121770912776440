<template>
  <div class="form-check form-check-switchery" :class="size" :name="name" :inputclass="inputclass" >
    <div class="checkbox checkbox-switchery mt-10">
      <label class="form-check-label" :style="switchStyle">
        <input ref="switchery" type="checkbox" v-model="data" :disabled="disabled" class="form-check-input-switchery switchery-danger">
        {{ label }}
      </label>
    </div>
    <div v-if="verr[0]" class="form-text errors text-danger">{{ verr[0] }}</div>
  </div>
</template>

<script>
export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  props: {
    modelValue: {
      type: [Boolean, Array],
      default: false
    },
    blockValue: {
      type: [Boolean, Array, String, Number],
      default: false
    },
    name: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    inputclass: {
      type: String,
      default: ""
    },
    size: {
      type: String,
      default: 'default'
    },
    placeholder: {
      type: String,
      default: ""
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    verr: {
      type: Array,
      default: []
    }
  },
  watch: {
    modelValue: function (value) {
      this.setSwitchery(this.switchery, value)
    },
    disabled(value, old) {
      if (value) {
        this.switchery.disable();
      }
      else {
        this.switchery.enable();
      }
    }
  },
  data() {
    return {
      switchery: null,

    }
  },
  computed: {
    data: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      }
    },
    switchStyle() {
      return {
        opacity: this.disabled ? '0.5' : '1'
      }
    }
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.switchery == null) {
        setTimeout(() => {
          //console.log(this.disabled);
          this.switchery = new Switchery(this.$refs.switchery, {
            disabled: this.disabled,
            secondaryColor: '#B1B5C3',
            size: this.size ?? 'default'
          })
          this.setSwitchery(this.switchery, this.data)
          if (this.disabled) this.switchery.disable();
        })

      }
    },
    setSwitchery: function (switchElement, checkedBool) {
      if ((checkedBool && !switchElement.isChecked()) || (!checkedBool && switchElement.isChecked())) {
        switchElement.setPosition(true);
        switchElement.handleOnchange(true);
      }
    }

  }
};
</script>
