<template>
	<div class="card-footer border-0 bg-transparent">
		<slot>
		</slot>
	</div>
</template>

<script>

export default {
}

</script>
