import MaskedInput from 'vue-text-mask'

import validation from '@components/ui/validation.vue'

export function createGlobalComponents(app) {

    app.component('masked-input', MaskedInput);

    app.component("validation", validation);
}
