<template>
  <validation class="form-check form-check-switchery" :validator="v" :name="name" :inputclass="inputclass">
    <div class="checkbox checkbox-switchery switchery-xs  mt-10">
      <label class="form-check-label">
        <input ref="switchery" type="checkbox" v-model="data" class="form-check-input-switchery switchery-danger">
        {{ label }}
      </label>
    </div>
    <div v-if="verr[0]" class="form-text errors text-danger">{{ verr[0] }}</div>
  </validation>
</template>

<script>


export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    inputclass: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    v: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    verr: {
      type: Array,
      default: []
    }
  },
  watch: {
    modelValue: function (value) {
      this.setSwitchery(this.switchery, value)
    }
  },
  data() {
    return {
      switchery: null,

    }
  },
  computed: {
    data: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.v.$touch();
        this.$emit("update:modelValue", value);
      }
    }
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.switchery == null) {
        this.switchery = new Switchery(this.$refs.switchery)
        this.setSwitchery(this.switchery, this.data)
      }
      ;
    },
    setSwitchery: function (switchElement, checkedBool) {
      if ((checkedBool && !switchElement.isChecked()) || (!checkedBool && switchElement.isChecked())) {
        switchElement.setPosition(true);
        switchElement.handleOnchange(true);
      }
    }

  }
};
</script>
