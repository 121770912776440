import momentFo from 'moment-timezone'

import {store} from '../store'
import _debounce from "lodash/debounce"
import _throttle from "lodash/throttle"
import _trim from "lodash/trim";
import _transform from "lodash/transform";
import _map from "lodash/map";
import _filter from "lodash/filter";
import _join from "lodash/join";
import _concat from "lodash/concat";
import _orderBy from "lodash/orderBy";
import _clone from "lodash/clone";
import _startsWith from "lodash/startsWith";
import _reduce from "lodash/reduce";
import _includes from "lodash/includes";

export default {
    beforeRouteUpdate(to, from, next) {
        const {asyncData} = this.$options
        if (asyncData) {
            asyncData({
                store: this.$store,
                route: to
            })
                .then(next)
                .catch(next)
        } else {
            next()
        }
    },
    watch: {
        loggedUser: function (newValue, oldValue) {
        }
    },
    computed: {
        loggedUser() {
            return store.getters['user/user'];
        },
        userPermissions() {
            if (!this.loggedUser) return [];
            return this.loggedUser.permissions.map(p => p.name)
        },
        userRoles() {
            if (!this.loggedUser) return [];
            return this.loggedUser.roles.map(p => p.name)
        },
        hasAdminRole() {
            return this.userRoles.includes('Admin')
        },
        hasHeinzRole() {
            return this.userRoles.includes('Heinz')
        },
        hasDashboardPermission() {
            return this.userPermissions.includes('Dashboard')
        },
        hasUsersPermission() {
          return this.userPermissions.includes('Users')
        },
        hasHeinzPermission() {
            return this.userPermissions.includes('Heinz')
        },
    },
    methods: {
        getBaseUrl() {
            return process.env.VUE_APP_PUBLIC_PATH;
        },
        getApiUrl() {
            return process.env.VUE_APP_API_URL;
        },
        asset(link = '', base = false) {
            return this.getBaseUrl() + (base ? '/../' : '') + link;
        },
        currency(value, digits = 2) {
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currencyDisplay: 'symbol',
                currency: 'USD',
                maximumFractionDigits: digits
            })
            return 'T ' + formatter.format(value);
        },
        userPhoneFormatted(phoneNumberString) {
            var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                return '(' + match[1] + ') ' + match[2] + '-' + match[3];
            }
            return phoneNumberString;
        },
        momentTZ(strDate, format = "MMMM Do YYYY h:mm A") {
            return momentFo(strDate).tz('America/New_York').format(format);
        },

        deleteObjProperty(object, property) {
            if (object.hasOwnProperty(property))
                delete object[property]
        },
        deepClone(data) {
            let type = typeof data;
            let obj;
            if (type === 'array') {
                obj = [];
            } else if (type === 'object') {
                obj = {};
            } else {
                //No more next level
                return data;
            }
            if (type === 'array') {
                for (var i = 0, len = data.length; i < len; i++) {
                    obj.push(this.deepClone(data[i]));
                }
            } else if (type === 'object') {
                for (var key in data) {
                    obj[key] = this.deepClone(data[key]);
                }
            }
            return obj;
        },

        _trim,
        _transform,
        _map,
        _filter,
        _join,
        _concat,
        _orderBy,
        _clone,
        _startsWith,
        _reduce,
        _includes,
        _throttle,
        _debounce
    }
}
