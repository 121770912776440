<template>
	<div  v-if="hasElements" >Showing entries from {{meta.from}} to {{meta.to}} of {{meta.total}}</div>
</template>

<script>

export default {
    props: {
        hasElements: {
            type: Boolean,
            default: true
        },
        meta: {
            type: Object,
            required: true
        }
    },
    computed: {

    }
};
</script>