/**
 * Import Dependency
 */
import axios from 'axios'
import { store } from '../store'
import { router } from '../router'

/**
 * Config
 */
axios.defaults.timeout = 1000 * 60

/**
 * Declare Variable
 */

/**
 * Config
 */
axios.interceptors.request.use(function(config) {
	let credential = store.state.auth.accessToken
	let isAuthorize = store.state.auth.isAuthorize
	if (credential!=='' && isAuthorize) {
		config.headers['Authorization'] = 'Bearer ' + credential
	}
	return config
}, error => {
	console.group('[Axios][Interceptor] Request Error')
	console.log(error)
	console.groupEnd()
	return Promise.reject(error)
})

axios.interceptors.response.use(data => {
	if (data.status === 401){
		router.push({
			name: 'auth.login',
			query: { redirect: router.currentRoute.fullPath }
		});
	}

	if (data.data.hasOwnProperty('status')){
		if (data.data.status.code === 401){
			router.push({
				name: 'auth.login',
				query: { redirect: router.currentRoute.fullPath }
			});
		}
	}

	return data;
}, error => {
	store.dispatch('pnotify/warning', {
        text: 'There was an error with your request: ' + error.message,
        addclass: 'bg-warning border-warning'
    }, {root:true});
    //return;
	//console.group('[Axios][Interceptor] Response Error')
	//console.log(error)
	//console.groupEnd()
	return Promise.reject(error)
})

export default axios
