<template>
  <select multiple="multiple">
    <slot></slot>
  </select>
</template>
<script>


export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  props: {
    modelValue: {
      default: []
    },
    options: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String,
      default: ""
    },
  },

  mounted: function () {
    $(this.$el)
        .select2({
          data: this.options,
          placeholder: vm.placeholder,
          minimumResultsForSearch: Infinity,
          minimumInputLength: 0,
        })
        .on('change', () => {
          this.updateSelection($(this).val())
        })
        .val(this.modelValue)
        .trigger('change');
  },
  data() {
    return {
      valuesData: []
    }
  },
  watch: {
    value: function (value) {
      this.informSelection(value);
    },
    valuesData: function (value) {

    },
    options: function (options) {
      $(this.$el).empty().select2({data: options})
    }
  },
  unmounted: function () {
    $(this.$el).off().select2('destroy')
  },
  methods: {
    updateSelection: function (v) {
      this.valuesData = v;
      this.informUpdate();
    },
    informSelection: function (v) {
      if ($(this.$el) !== v) {
        $(this.$el)
            .val(v)
            .trigger('change');
      }
    },
    informUpdate: function () {
      if (this.valuesData.length !== this.modelValue.length)
        this.$emit("update:modelValue", this.valuesData)
    },


  }
}
</script>
