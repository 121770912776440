<template>
  <validation v-if="isLoaded" class="form-group" :validator="v" :name="name" :inputclass="inputclass">
    <label v-if="label != ''">{{ label }}</label>
    <div class="input-group">
            <span class="input-group-prepend" v-if="simbol != '' && simbol != '%' ">
                <span class="input-group-text">$</span>
            </span>
      <masked-input
          type="text"
          class="form-control text-center"
          v-bind:class="[  true ? inputclass : '' ]"
          v-model="data"
          :mask="numberMask(decimals)"
          :guide="false"
          placeholderChar="#"
          :disabled='isLoading'
          :placeholder="placeholder">
      </masked-input>
      <span class="input-group-append" v-if="simbol == '%' ">
                <span class="input-group-text">%</span>
            </span>
    </div>
    <div v-if="verr[0]" class="form-text errors text-danger">{{ verr[0] }}</div>
  </validation>
</template>

<script>

import createNumberMask from 'text-mask-addons/dist/createNumberMask'

export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    inputclass: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    v: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    verr: {
      type: Array,
      default: []
    },
    decimals: {
      default: 2
    },
    simbol: {
      default: '$'
    }
  },
  data() {
    return {
      valueData: '',
      loaded: false
    }
  },
  watch: {
    valueData: function (value) {
      this.$emit("update:modelValue", value)
    }
  },
  mounted() {
    this.initialize()
  },
  computed: {
    isLoaded() {
      return this.loaded
    },
    price: {
      get: function () {
        return this.valueData + ''
      },
      // setter
      set: function (newValue) {
        this.valueData = newValue
      }
    },
    data: {
      get() {
        return this.modelValue + '';
      },
      set(value) {
        this.$emit("update:modelValue", value);
      }
    }
  },
  methods: {
    initialize: function () {
      this.valueData = this.modelValue;
      this.loaded = true;
    },
    numberMask: function (decimals) {
      return createNumberMask({
        prefix: '',
        suffix: '',
        decimalLimit: decimals,
        includeThousandsSeparator: false,
        allowDecimal: true,
      })
    }
  }

};
</script>
