<template>
	<cardFooter v-if="hasElements" class="d-sm-flex text-grey-300 justify-content-sm-between align-items-sm-center">
		<info :hasElements="hasElements" :meta="meta"></info>
		<pages :hasElements="hasElements" :meta="meta" @pagechanged="changePage"></pages>
	</cardFooter>
</template>

<script>

import info from './info.vue'
import pages from './pages.vue'
import uiCard from '@components/layout/card'


export default {
	components : {
		info,
		pages,
		'cardFooter' : uiCard.cardFooter
	},
    props: {
        hasElements: {
            type: Boolean,
            default: true
        },
        meta: {
            type: Object,
            required: true
        }
    },
    computed: {

    },
    methods : {
        changePage : function (page) {
            this.$emit('pagechanged', page)
        }
    }
};
</script>
