<template>
    <router-view v-if="dataReady"></router-view>
    <div v-else-if="!dataReady" class="loaderscreen">
        <div class="loader buzz"></div>
        <div v-if="message != '' " class="loadingmessage">{{message}}</div>
    </div>
</template>

<script>
export default {

    data() {
        return {
            dataReady: false,
            message: 'Loading ...',
        }
    },
    mounted: function () {
        let externalScript = document.createElement('script')
        externalScript.setAttribute('src', process.env.VUE_APP_PUBLIC_PATH + 'js/plugins.js')
        document.head.appendChild(externalScript)
        this.checkAuth();
    },
    computed : {
        isAuthorize () {
            return this.$store.getters.get.auth.isAuthorize
        },
        user () {
            return this.$store.getters.get.user.user
        }
    },

    methods:{
        checkAuth (){
            if ( ! this.isAuthorize){
                this.displayApp ();
                //this.dataReady = true;
                //this.$store.dispatch('auth/logout')
                //this.$router.push('/auth/login');
                return;
            }
            this.message = 'Please wait  ... '
            //try to load user
            if (this.user == null){
                this.loadUser();
            }else{
                this.displayApp();
            }
        },
        loadUser(){
            this.$store.dispatch('auth/me')
            .then((response) => {
                this.message = 'Please wait ...'
                this.displayApp();
            })
            .catch( error => {
                this.dataReady = true;
                this.$store.dispatch('auth/logout')
                this.$router.push('/auth/login');
            });
        },
        displayApp () {
            this.dataReady = true;
        }
    }
}
</script>
<style lang="scss" src="@/scss/general.scss"></style>
