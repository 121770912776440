<template>
  <div class="card-body bg-white rounded pt-4 pb-4 shadow shadow-1 shadow-sm border-0" v-if="isLoaded">
    <div class="row">
      <div class="col-12 pl-4 pr-4">
        <h4>Update Password</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 pl-4 pr-4">
        <uiformgrouppassword :isRequired="true" v-model="v$.form.password.$model" :v="v$.form.password" :isLoading="isLoading" name="password" label="Password"  inputclass="form-control-lg" :verr="errors.password || []"/>
      </div>
      <div class="col-md-4 pl-4 pr-4">
        <uiformgrouppassword :isRequired="true" v-model="v$.form.password_confirmation.$model" :v="v$.form.password_confirmation" :isLoading="isLoading" name="password_confirmation" label="Password confirmation"  inputclass="form-control-lg" :verr="errors.password_confirmation || []"/>
      </div>
      <div class="col-md-4 pl-4 pr-4">
        <div class="form-group">
          <label style="display: block;">&nbsp;</label>
          <uibutton text="Update" v-on:click="save" :isLoading="isLoading" class="btn-lg rounded-pill text-center px-4">
            <i v-if='!isLoading' class="icon-arrow-right22 ml-3"></i>
            <i v-if='isLoading' class="icon-spinner2 spinner ml-3"></i>
          </uibutton>
        </div>
      </div>
    </div>

  </div>
	<div v-else >
		<div style="height: 100px">
			<div class="loader loader-inline no-margin-top"></div>
		</div>
	</div>
</template>
<script>
import { required, alphaNum, requiredIf,  email, string , minLength} from '@vuelidate/validators'
import  ui  from '@components/ui'
import useVuelidate from "@vuelidate/core";

export default {
	name: 'AdminEdit',
  setup () {
    return { v$: useVuelidate() }
  },
	components : {
		'uiformgrouptext' : ui.formgrouptext,
		'uiformgrouptextarea' : ui.formgrouptextarea,
		'uiformgroupWYSIWYG' : ui.formgroupWYSIWYG,
		'uiformgroupswitch' : ui.formgroupdoubleswitch,
		'uiformgroupselect2' : ui.formgroupselect2,
		'uiformgroupprice' : ui.formgroupprice,
		'uiformswitch' : ui.formswitch,
		'uibutton' : ui.button,
		'uiformgroupdatepicker' : ui.formgroupdatepicker,
		'uiformgrouptextphone' : ui.formgrouptextphone,
		'uiformgrouppassword' : ui.formgrouppassword
	},
	props : {
        elementData: {
            type: Object,
            default : null
        },
    },
	data() {
		return {
			element : null,
			elementReady : false,
			form: {
				password: "",
				password_confirmation: "",
		    },
		    misc : null,
			errors : {},
			isLoading : false
		}
	},
	watch: {
		element(value){
			if (value !== null ) this.initializeElement();
 		},
		misc(){},
		isLoaded(){},
		password (value, old) {
            this.deleteObjProperty(this.errors, 'password')
		},
		password_confirmation (value, old) {
            this.deleteObjProperty(this.errors, 'password_confirmation')
        },
    },
	mounted(){
		this.initialize();
	},
	computed: {
		password_confirmation() {
			return this.form.password_confirmation
		},
		password() {
			return this.form.password
		},
		isLoaded(){
			return this.element != null && this.elementReady
		},

	},
	validations: {
		form: {
			password : { required , minLength: minLength(8) },
			password_confirmation : { required , minLength: minLength(8)},
		}
	},
	methods: {
		initialize() {
			this.element = this.elementData;
			//this.loadMisc();
			//this.initializeElement();
        },
        initializeElement : function(){
			this.elementReady = true;
			
        },
        loadMisc : function(){
        },
        cancel : function(){
        	this.$router.push({name: 'users-view-page', params: { page: this.$route.query.page ? this.$route.query.page : 1 } });
        },
        save() {
			this.v$.form.$touch();
			// if its still pending or an error is returned do not submit
  			if (this.v$.form.$pending || this.v$.form.$error) {
  				this.$store.dispatch('pnotify/alert', {
			        text: 'Please check the errors.',
			        addclass: 'bg-danger border-danger'
			    }, {root:true});
			    return;
			}

			this.isLoading = true;
			this.errors = {}

			this.$store.dispatch('users/updatepassword', { ...{id : this.element.id}, ...this.form})
			.then((response) => {
				this.isLoading = false;
				this.$router.push({name: 'users-view-page', params: { page: this.$route.query.page ? this.$route.query.page : 1 } });
			})
			.catch( error => {
				this.isLoading = false;
				this.errors = error.status.errors
            });
		}
	}
}
</script>
