<template>
  <validation class="form-group" :validator="v" :name="name" :inputclass="inputclass">
    <label v-if="label != ''">{{ label }} <sup v-if="isRequired" class="text-danger">*</sup></label>
    <input type="text" :disabled='isLoading || isDisabled' v-model.trim.lazy="data" class="form-control"
           @keypress="isNaturalNumberPrice($event)"
           @keyup="onKeyUp($event)"
           @keyup.enter="onKeyUpEnter($event)"
           v-bind:class="[ (v.$error || verr[0]) ? 'border-danger' : '' , true ? inputclass : '' ]"
           :placeholder="placeholder" autocomplete="no">
    <div v-if="verr[0]" class="form-text errors text-danger">{{ verr[0] }}</div>
  </validation>
</template>

<script>
export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    inputclass: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    v: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    verr: {
      type: Array,
      default: []
    }
  },
  computed: {
    data: {
      get() {
        return this.modelValue;
      },
      set(modelValue) {
        this.v.$touch();
        this.$emit("update:modelValue", modelValue);
      }
    }
  },
  methods: {
    isNaturalNumberPrice : function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) evt.preventDefault();
      return true;
    },
    onKeyUp($event) {
      this.$emit('keyup',$event);
    },
    onKeyUpEnter($event) {
      this.$emit('keyup.enter',$event);
    }
  }
};
</script>
