import view from './index.vue'
import add from './add.vue'
import edit from './edit.vue'
import changepassword from './changepassword.vue'

export default {
	view,
	add,
	edit,
	changepassword
}