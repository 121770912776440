/**
 * Import Dependency
 */
import axios from '../../../interceptor'

/**
 * Declare Variable
 */
const baseUrl = process.env.VUE_APP_API_URL



const getGetUrl = `${baseUrl}/admin/heinz`
const getDeleteUrl = `${baseUrl}/admin/heinz/delete`
const getGetByIdUrl = `${baseUrl}/admin/heinz/get`
const getStatusUrl = `${baseUrl}/admin/heinz/status`
const getMiscUrl = `${baseUrl}/admin/heinz/misc`
const downloadCsvUrl = `${baseUrl}/admin/heinz/export`
const drawWinnersUrl = `${baseUrl}/admin/heinz/winners`

/**
 * Export
 */
export default {

	get(data) {
		return axios.post(getGetUrl, data)
	},
	misc(data) {
		return axios.get(getMiscUrl, data)
	},
    delete(data) {
        return axios.post(getDeleteUrl, data)
    },
	status(data) {
		return axios.post(getStatusUrl, data)
	},
	getById(data) {
		return axios.post(getGetByIdUrl, data)
	},
	downloadCSV(data) {
		return axios.post(downloadCsvUrl, data)
	},
	drawWinners(data) {
		return axios.post(drawWinnersUrl, data)
	},
}
