/**
 * Import Dependency
 */
import * as types from '../../mutations_types'

/**
 * Import API
 */
import usersAPI from '@appservices/api/app/users'

/**
 * Declare Variable
 */
const state = {
    filters : {
		status : '',
		search : '',
        role : '',
        created : {
            from : '',
            to : ''
        },
    },
    options : {
        per_page   : 10,
        order_by   : 'created_at',
        order_type : 'desc'
    },
}
const getters = {
    filters : state => {
		return state.filters
    },

    options : state => {
		return state.options
    },

    per_page : (state, rootGetters) => {
		return rootGetters['page/per_page']
	},
}
const actions = {
    setOptions({ commit, dispatch }, options) {
        commit('setOptions', options)
    },

    setFilters({ commit, dispatch }, filters) {
        commit('setFilters', filters)
    },
    resetFilters({ commit, dispatch }, filters) {
        commit('setFilters', {
            search : '',
            status : '',
            role: '',
            created : {
                from : '',
                to : ''
            },
        })
    },   
    
    async get({ commit, dispatch }, context) {
		try {
			const response = await usersAPI.get(context)
			if (response.data.status.success){
				return Promise.resolve(response.data)
			}else{
				dispatch('pnotify/alert', {
			        text: response.data.status.message,
			        addclass: 'bg-danger border-danger'
			    }, {root:true});
				return Promise.reject(response.data)
			}

		} catch (e) {
			//return Promise.reject(e)
		}
    },
    
	async misc({ commit, dispatch }, context) {
		try {
			const response = await usersAPI.misc(context)
			if (response.data.status.success){
				return Promise.resolve(response.data)
			}else{
				dispatch('pnotify/alert', {
			        text: response.data.status.message,
			        addclass: 'bg-danger border-danger'
			    }, {root:true})
			}
		} catch (e) {
			//return Promise.reject(e)
		}
	},

	async getById({ commit, dispatch }, context) {
		try {
			const response = await usersAPI.getById(context)
			if (response.data.status.success){
				return Promise.resolve(response.data)
			}else{
				dispatch('pnotify/alert', {
			        text: response.data.status.message,
			        addclass: 'bg-danger border-danger'
			    }, {root:true});
				return Promise.reject(response.data)
			}

		} catch (e) {
			//return Promise.reject(e)
		}
	},
    
	async add({ commit, dispatch }, context) {
		try {
			const response = await usersAPI.add(context)
			if (response.data.status.success){
				dispatch('pnotify/alert', {
			        text: response.data.status.message,
			        addclass: 'bg-primary border-primary'
			    }, {root:true});
				return Promise.resolve(response.data)
			}else{
				dispatch('pnotify/alert', {
			        text: response.data.status.message,
			        addclass: 'bg-danger border-danger'
			    }, {root:true});
				return Promise.reject(response.data)
			}

		} catch (e) {
			//return Promise.reject(e)
		}
    },
    
	async update({ commit, dispatch }, context) {
		try {
			const response = await usersAPI.update(context)
			if (response.data.status.success){
				dispatch('pnotify/alert', {
			        text: response.data.status.message,
			        addclass: 'bg-primary border-primary'
			    }, {root:true});
				return Promise.resolve(response.data)
			}else{
				dispatch('pnotify/alert', {
			        text: response.data.status.message,
			        addclass: 'bg-danger border-danger'
			    }, {root:true});
				return Promise.reject(response.data)
			}

		} catch (e) {
			//return Promise.reject(e)
		}
    },

	async updatepassword({ commit, dispatch }, context) {
		try {
			const response = await usersAPI.updatepassword(context)
			if (response.data.status.success){
				dispatch('pnotify/alert', {
					text: response.data.status.message,
					addclass: 'bg-primary border-primary'
				}, {root:true});
				return Promise.resolve(response.data)
			}else{
				dispatch('pnotify/alert', {
					text: response.data.status.message,
					addclass: 'bg-danger border-danger'
				}, {root:true});
				return Promise.reject(response.data)
			}

		} catch (e) {
			//return Promise.reject(e)
		}
	},
    
	async status({ commit, dispatch }, context) {
		try {
			const response = await usersAPI.status(context)
			if (response.data.status.success){
				return Promise.resolve(response.data)
			}else{
				return Promise.reject(response.data)
			}
		} catch (e) {
			//return Promise.reject(e)
		}
    },

	async delete({ commit, dispatch }, context) {
		try {
			const response = await usersAPI.delete(context)
			if (response.data.status.success){
				return Promise.resolve(response.data)
			}else{
				dispatch('pnotify/alert', {
			        text: response.data.status.message,
			        addclass: 'bg-danger border-danger'
			    }, {root:true});
			}
		} catch (e) {
			//return Promise.reject(e)
		}
	},
}
const mutations = {
    setFilters(state, value) {
		state.filters = { ...value };		
    },

    setOptions(state, value) {
		state.options = { ...value };		
	}
}

/**
 * Export
 */
export default {
	namespaced: true,
	state,
	getters,
	actions,
    mutations
}
