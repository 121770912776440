<template>
	<div class="content-wrapper">
		<pageheader title="Heinz Participants"></pageheader>
		<div class="content">
            <div v-if="isLoaded" class="row">
                <div class="col-md-12">
                  <uiheinz :misc="misc"></uiheinz>
                </div>
            </div>
            <div v-else>
                <div style="height: 100px">
                    <div class="loader loader-inline mt-5"></div>
                </div>
            </div>
		</div>
	</div>
</template>

<script>
import pageheader from '@components/layout/header/index'
import uiheinz from '@components/pages/heinz'

export default {
	name: 'HeinzView',
	components : {
		pageheader,
    'uiheinz' : uiheinz.view
	},
	data() {
		return {
            misc : [],
            isLoaded : false
		}
	},
	mounted () {
        this.initialize();
    },
	computed : {
        data(){
            return {
            }
        }
	},
	methods : {
        initialize() {
           this.loadConfig();
        },
        loadConfig : function(){
            this.$store.dispatch('heinz/misc')
            .then((response) => {
                this.misc = response.data;
                this.isLoaded = true;
            })
            .catch( error => {});
        }
    }
}
</script>
