/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
require('./bootstrap');

import { createApp } from 'vue';
import { router } from './js/router'
import { store } from './js/store'
import globalMixins from './js/mixins/global'
import { createGlobalComponents } from '@components/globals'
import { createHelpers } from './js/helpers'

import App from './App.vue'

const app = createApp(App)

createGlobalComponents(app);
createHelpers(app);

app.use(store);
app.use(router);

// a global mixin that calls `asyncData` when a route component's params change
app.mixin(globalMixins);

import VueSweetalert2 from 'vue-sweetalert2';
const swalOptions = {
    showClass: {
        popup: 'animate_fade_in',
    },
    hideClass: {
        popup: 'animate_fade_out'
    },
    allowOutsideClick: false,
}
app.use(VueSweetalert2, swalOptions);

// actually mount to DOM
app.mount('#app');
