<template>
    <li v-if="hasSidebar" class="nav-item">
        <a href="javascript:void(0)" class="navbar-nav-link sidebar-control sidebar-main-toggle d-none d-md-block opacity-0">
            <i class="icon-paragraph-justify3"></i>
        </a>
    </li>

</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: 'sidebar',

    components : {

    },
    watch: {

    },
    data () {
        return {

        }
    },
    computed: {
        ...mapGetters("page" , ['hasSidebar']),
    },
    mounted(){
        this.$nextTick(function () {
            //Application.initSidebars();
        })
    },
    unmounted(){

    },
    methods : {

    }


}
</script>
