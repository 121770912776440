<template>
  <select :disabled="isDisabled">
    <option v-for="option in options" :value="option[valueField]" :key="option[valueField]">{{ option[labelField] }}</option>
  </select>
</template>
<script>


export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  components: {},
  props: {
    modelValue: {
      default: ""
    },
    options: {
      type: Array,
      default: []
    },
    placeholder: {
      type: String,
      default: ""
    },
    defaultText: {
      type: String,
      default: "Please select..."
    },
    hideSearchBox: {
      type: Boolean,
      default: false
    },
    allowClear: {
      type: Boolean,
      default: true
    },
    valueField: {
      type: String,
      default: 'id'
    },
    labelField: {
      type: String,
      default: 'name'
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
  }
  ,
  mounted() {
    this.initialize();
  },
  watch: {
    selectedValue(newValue, oldValue) {
      this.$emit("update:modelValue", newValue)
    },
    options: {
      handler(newValue, oldValue) {
        //this.selectedValue = []
        //if (newValue != this.selectedValue)
        //$(this.$el).val(newValue).trigger("change")
      },
      deep: true
    },
    modelValue(newValue, old) {
      this.selectedValue = newValue;
      $(this.$el).val(newValue).trigger("change")
    },
  },
  unmounted: function () {
    $(this.$el).off().select2('destroy')
  },
  data() {
    return {
      selectedValue: ""
    }
  },
  computed: {},
  methods: {
    initialize() {
      this.selectedValue = this.modelValue
      var vm = this;
      $(this.$el)
          // init select2
          .select2({
            placeholder: vm.placeholder ? vm.placeholder : vm.defaultText,
            minimumInputLength: 0,
            minimumResultsForSearch: vm.hideSearchBox ? Infinity : 5,
            allowClear: vm.allowClear,
          })
          .val(vm.selectedValue)
          .trigger('change')
          // emit event on change.
          .on('change', function () {
            vm.selectedValue = $(this).val()
          });

    },
    removeSel2() {
      $(this.$el).select2('destroy')
    },
  }
}
</script>
