<template>
  <router-view></router-view>
</template>
<script>

export default {
  data() {
    return {}
  },
  components: {},
  mounted: function () {
  },
  watch: {},
  computed: {},
}
</script>
