/**
 * Import Dependency
 */
import * as types from '../../mutations_types'

/**
 * Import API
 */
import dashboardAPI from '@appservices/api/app/dashboard'

/**
 * Declare Variable
 */
const state = {

}
const getters = {

}
const actions = {

    async get({ commit, dispatch }, context) {
		try {
			const response = await dashboardAPI.get(context)
			if (response.data.status.success){
				return Promise.resolve(response.data)
			}else{
				dispatch('pnotify/alert', {
			        text: response.data.status.message,
			        addclass: 'bg-danger border-danger'
			    }, {root:true});
				return Promise.reject(response.data)
			}

		} catch (e) {
			//return Promise.reject(e)
		}
    },
}
const mutations = {
}

/**
 * Export
 */
export default {
	namespaced: true,
	state,
	getters,
	actions,
    mutations
}
