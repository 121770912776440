<template>
  <div class="page-content">

    <!-- Main content -->
    <div class="content-wrapper">

      <!-- Content area -->
      <div class="content d-flex justify-content-center align-items-center">

        <!-- Container -->
        <div class="flex-fill">

          <!-- Error title -->
          <div class="text-center mb-3">
            <h3 class="error-title">404</h3>
            <h5>Oops, page not found!</h5>
            <router-link :to="{ name : 'dashboard' }" class="btn btn-primary btn-lg mt-4" tag="button">
              Main Page
            </router-link>
          </div>
          <!-- /error title -->


          <!-- Error content -->
          <div class="row">
            <div class="col-xl-4 offset-xl-4 col-md-8 offset-md-2">

              <!-- Buttons -->
              <div class="row">
                <div class="col-sm-6 text-center">

                </div>
              </div>
              <!-- /buttons -->

            </div>
          </div>
          <!-- /error wrapper -->

        </div>
        <!-- /container -->

      </div>
      <!-- /content area -->

    </div>
    <!-- /main content -->

  </div>
</template>

<script>
export default {
  name: 'pageNotFound',
  data() {
    return {
      title: 'Error 404'
    }
  }
}
</script>
