<template>
  <validation class="form-group" :validator="v" :name="name" :inputclass="inputclass">
    <label v-if="label != ''">{{ label }} <sup v-if="isRequired" class="text-danger">*</sup></label>

    <div class="flex w-full h-screen items-center justify-center text-center" >
      <div class="p-3 border border-gray-300" @dragover="dragover" @dragleave="dragleave" @drop="drop">
        <input type="file" :multiple="multiple" name="fields[assetsFieldHandle][]" :id="'upload_'+name"
               class="w-px h-px opacity-0 overflow-hidden position-absolute" @change="onChange" ref="file" accept=".pdf,.jpg,.jpeg,.png" />

        <label :for="'upload_'+name" class="block cursor-pointer mb-0">
          <div>
            Click to upload or drag files here.
          </div>
        </label>
        <div class="mt-2 text-left" v-if="this.filelist.length || previous" v-cloak>
          <div v-if="previous && !this.filelist.length">
            <div class="flex">
              <a :href="previous" target="_blank">{{previousFileName}}</a>
              <a class="text-danger cursor-pointer ml-4" @click="removePrevious"><i class="iconx-delete"></i></a>
            </div>
          </div>
          <div class="text-sm p-1 position-relative" v-for="file in filelist">
            <a class="text-danger float-right cursor-pointer" @click="remove(filelist.indexOf(file))"><i class="iconx-delete"></i></a>
            {{ file.name }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="verr[0]" class="form-text text-danger">{{ verr[0] }}</div>
  </validation>
</template>

<script>

import FileUpload from 'vue-upload-component'

export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  components: {
    FileUpload
  },
  props: {
    modelValue: {
      type: [String, Boolean],
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    inputclass: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: "Click or drag files here to upload"
    },
    v: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    previous: {
      type: String,
      default: ''
    },
    verr: {
      type: Array,
      default: []
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.v.$touch();
        this.$emit("update:modelValue", value);
      }
    },
    previousFileName() {
      return this.previous;
    }
  },
  data() {
    return {
      filelist: []
    }
  },
  watch: {
    modelValue(value) {
      if (!value) {
        this.filelist = [];
      }
    },
    filelist: {
      deep: true,
      handler(value) {
        console.log(value);
        if (this.multiple) {
          this.value = value;
        }
        else {
          if (value && value.length) {
            this.value = value[0];
          }
          else {
            this.value = null;
          }
        }
      }
    }
  },
  methods: {
    removePrevious: function () {
      this.value = null;
      this.$emit('previous:delete');
    },

    onChange() {
      this.filelist = [...this.$refs.file.files];
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('border-success')) {
        event.currentTarget.classList.remove('border-gray-300');
        event.currentTarget.classList.add('border-success');
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add('border-gray-300');
      event.currentTarget.classList.remove('border-success');
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add('border-gray-300');
      event.currentTarget.classList.remove('border-success');
    }

  }
};
</script>
