<template>
  <pageCard>
    <uiheader />
    <uifilters :roles="misc.roles" :meta="meta" :loading="loading"/>
    <uielements :loading="loading" :elements="elements" :meta="meta" @element:updated="elementsUpdated"/>
    <uilistfooter class="" :hasElements="hasElements" :meta="meta" @pagechanged="changePage"/>
  </pageCard>
</template>

<script>

import uiCard from '@components/layout/card'
import uilistfooter from '@components/ui/pagination/listfooter.vue'
import uiElements from './helpers/elements.vue'
import uiHeader from './helpers/header.vue'
import uifilters from './helpers/filters.vue'

export default {
  components: {
    'pageCard': uiCard.card,
    'uielements': uiElements,
    'uiheader': uiHeader,
    'uifilters': uifilters,
    uilistfooter
  },

  props: {
    misc: {
      type: Object,
      required: true
    }
  },
  watch: {
    options: {
      handler: function (value) {
        if (this.monitorChanges) {
          this.goToPage(1)
        }
      },
      deep: true
    },
    filters: {
      handler: function (value) {
        if (this.monitorChanges) {
          this.goToPage(1)
        }
        ;
      },
      deep: true
    },
    '$route.params.page': function (page) {
      if (page && page != this.meta.current_page) {
        this.goToPage(page)
      }
    }
  },
  data() {
    return {
      loading: {
        status: false,
        message: 'Loading ...',
      },
      monitorChanges: false,
      elements: [],
      errors: [],
      meta: {
        total: 0,
        per_page: 0,
        current_page: 1,
        last_page: 0,
        from: 0,
        to: 0
      },
    }
  },
  computed: {
    filters: function () {
      return this.$store.getters['heinz/filters'];
    },
    options: function () {
      return this.$store.getters['heinz/options'];
    },

    isLoading: function () {
      return this.loading.status
    },
    hasElements: function () {
      return this.elements.length > 0;
    },
    data() {
      return {
        options: this.options,
        filters: this.filters
      }
    },
  },
  created() {
    if (this.$route.params.page) {
      this.meta.current_page = parseInt(this.$route.params.page);
    } else {
      this.meta.current_page = 1
    }

  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.get(this.meta.current_page);
    },
    changePage: function (page) {
      this.goToPage(page)
    },
    elementsUpdated: function () {
      this.get(this.meta.current_page);
    },
    goToPage(page) {
      this.meta.current_page = page;
      this.get(page);
    },

    get: function (page = 1) {
      if (this.meta.current_page !== page) {
        this.$router.push({name: 'heinz-view-page', params: {page}})
      }

      this.loading.status = true;
      this.$store.dispatch('heinz/get', {...this.data, ...{page: page}})
          .then((response) => {
            this.loading.status = false;
            this.elements = response.data.entries
            //redirect if requested page is greater than last_page
            if (this.meta.current_page > response.data.meta.last_page) {
              this.get(1)
            }
            this.meta = response.data.meta
            this.monitorChanges = true;
          })
          .catch(error => {
            this.loading.status = false;
            this.errors = error.status.errors
          });
    },

  }
};
</script>
