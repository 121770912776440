<template>
	<ul v-if="hasElements && hasPages" class="pagination pagination-flat pagination-sm justify-content-around mt-2 mt-sm-0">
        <li class="page-item" v-bind:class="{ disabled : meta.current_page == 1}"><a href="javascript:void(0)" class="page-link" v-on:click="changePage(1)" >←</a></li>

        <li class="page-item" v-for="page in meta.last_page" v-bind:class="{ active: page == meta.current_page}">
            <a v-if="inDisplayedPages(page)" class="page-link"  v-on:click="changePage(page)" href="javascript:void(0)">{{page}}</a>
        </li>


        <li class="page-item" v-bind:class="{ disabled : meta.current_page ==  meta.last_page}"><a href="javascript:void(0)" class="page-link" v-on:click="changePage(meta.last_page)" >→</a></li>
    </ul>
</template>

<script>

export default {
    props: {
        hasElements: {
            type: Boolean,
            default: true
        },
        meta: {
            type: Object,
            required: true
        }
    },
    computed: {
        hasPages : function(){
            return this.meta.total > this.meta.per_page;
        },
        toDisplayPagesBefore : function(){
            return 5
        },
        toDisplayPagesAfter : function(){
            return 5
        },
    },
    methods : {
        changePage : function (page) {
            this.$emit('pagechanged', page)
        },
        inDisplayedPages :  function (page){
            return  ((page > (this.meta.current_page - this.toDisplayPagesBefore)) && (page < (this.meta.current_page + this.toDisplayPagesAfter)));
        }
    }
};
</script>