<template>
    <li v-if="ready" class="nav-item dropdown dropdown-user">
        <a href="#" class="navbar-nav-link dropdown-toggle" data-toggle="dropdown">
            <span>{{loggedUser.first_name}} {{loggedUser.last_name}}</span>
        </a>

        <div class="dropdown-menu dropdown-menu-right">
          <!--
            <a href="#" class="dropdown-item"><i class="icon-user-plus"></i> My profile</a>
            <div class="dropdown-divider"></div>
            -->
            <a href="javascript:void(0)" class="dropdown-item" v-on:click="logout"><i class="icon-switch2"></i> Logout</a>
        </div>
    </li>

</template>
<script>

export default {
    name: 'user',

    components : {

    },
    watch: {
        loggedUser : function (newValue, oldValue) {
            this.ready = (newValue !== null )
        }
    },
    data () {
        return {
            ready : false
        }
    },
    computed: {
        userFullName(){
            return (this.loggedUser !== null ) ? this.loggedUser.first_name + ' '  + this.loggedUser.first_name : ''
        }
    },
    mounted(){
        this.ready = (this.loggedUser !== null )
    },
    unmounted(){

    },
    methods : {
        logout : function(){
            this.$store.dispatch('pnotify/alert', {
                title: `See you soon ${this.loggedUser.first_name} ${this.loggedUser.last_name}.`,
                text: '',
                addclass: 'bg-primary border-primary'
            }, {root:true});
            this.$store.dispatch('auth/logout');
            this.$router.push('/auth/login');
        }
    }


}
</script>
