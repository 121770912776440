<template>
    <router-link :to="{ name : b.link }" active-class="breadcrumb-item-active" class="breadcrumb-item" >
        <span>{{b.name}}</span>
    </router-link>
</template>

<script>

export default {
    props: {
        breadcrumb: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },
    components : {
    },
    computed : {
        b() { return this.breadcrumb}
    },

}

</script>
