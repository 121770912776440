import Default from './PageDefault.vue';
import Auth from './auth';
import App from './application';
import Errors from './errors';

export default {
	Default,
	Auth,
	App,
	Errors
}
