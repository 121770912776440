<template>
  <!-- Main sidebar -->
  <div class="sidebar sidebar-dark sidebar-main sidebar-fixed  sidebar-expand-md">

    <!-- Sidebar mobile toggler -->
    <div class="sidebar-mobile-toggler text-center">
      <a href="#" class="sidebar-mobile-main-toggle">
        <i class="icon-arrow-left8"></i>
      </a>
      Navigation
      <a href="#" class="sidebar-mobile-expand">
        <i class="icon-screen-full"></i>
        <i class="icon-screen-normal"></i>
      </a>
    </div>
    <!-- /sidebar mobile toggler -->


    <!-- Sidebar content -->
    <div class="sidebar-content">

      <!-- Main navigation -->
      <div class="card card-sidebar-mobile pb-5">
        <ul class="nav nav-pills nav-sidebar" data-nav-type="accordion">

          <logo></logo>

          <!-- Main -->
          <!--<li class="nav-item-header">
            <div class="text-uppercase font-size-xs line-height-xs">Main</div>
            <i class="icon-menu" title="Main"></i></li>
            -->
          <li class="nav-item" v-if="hasDashboardPermission">
            <router-link :to="{ name : 'dashboard' }" active-class="active" class="nav-link">
              <i class="iconx-home"></i> <span>Dashboard</span>
            </router-link>
          </li>
          <li  class="nav-item" v-if="hasUsersPermission" >
            <router-link :to="{ name : 'users-view'}" v-bind:class="[isUsersActive ? 'active' : '']" active-class="active" class="nav-link">
              <i class="iconx-account"></i> <span>Accounts</span>
            </router-link>
          </li>
          <li  class="nav-item" v-if="hasHeinzPermission" >
            <router-link :to="{ name : 'heinz-view'}" v-bind:class="[isHeinzActive ? 'active' : '']" active-class="active" class="nav-link">
              <i class="iconx-participants"></i> <span>{{hasAdminRole ? 'Heinz' : 'Sweepstakes'}} Entries</span>
            </router-link>
          </li>

        </ul>

      </div>
      <!-- /main navigation -->

      <a class="cursor-pointer sidebar-main-toggle position-absolute bottom-0 right-0 mr-2 mb-2 p-1"
         @click="toggleSidebar"
      ><i :class="sidebarCollapsed ? 'iconx-expand' : 'iconx-minimize'"></i></a>

    </div>
    <!-- /sidebar content -->

  </div>
  <!-- /main sidebar -->
</template>
<script>

import Logo from "@components/layout/navbar/helpers/Logo";
import {ROLE_ADMIN} from "@base/constants";
import {mapGetters, mapActions} from "vuex";
export default {
  name: 'sidebar',
  components: {Logo},
  data() {
    return {}
  },
  watch: {
    '$route'(to, from) {
    },
  },
  mounted() {
    this.$store.dispatch('page/setSidebar');
    if (this.sidebarCollapsed) {
      $('body').addClass('sidebar-xs');
    }
  },
  beforeUnmount() {
    this.$store.dispatch('page/removeSidebar');
  },
  computed: {

    ...mapGetters("page" , ['sidebarCollapsed']),

    userRoles() {
      if (!this.loggedUser || !this.loggedUser.roles) return [];
      return this.loggedUser.roles.map(p => p.id)
    },
    userPermissions() {
      if (!this.loggedUser) return [];
      return this.loggedUser.permissions.map(p => p.name)
    },
    hasAdminRole() {
      return this.userRoles.includes(ROLE_ADMIN);
    },

    isUsersActive() {
      return [
        'users',
        'users-view',
        'users-view-page',
        'users-edit',
        'users-add'
      ].includes(this.$route.name);
    },
    isHeinzActive() {
      return [
        'heinz',
        'heinz-view',
        'heinz-view-page',
        'heinz-edit',
        'heinz-add'
      ].includes(this.$route.name);
    },
  },

  methods: {
    toggleSidebar() {
      this.$store.dispatch('page/toggleSidebar');
    }
  }


}

</script>
