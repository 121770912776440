/**
 * Import Dependency
 */
import * as types from '../mutations_types'

/**
 * Import API
 */
import authAPI from '../../services/api/auth'

/**
 * Declare Variable
 */
const state = {
	isAuthorize: JSON.parse(localStorage.getItem('isAuthorize')) || false,
	accessToken: localStorage.getItem('accessToken') || '',
}
const getters = {
	getAccessToken: state => state.accessToken,
	getIsAuthorize: state => state.isAuthorize
}
const actions = {
	async signin({ commit, dispatch }, context) {
		try {
			const response = await authAPI.signin(context)

			if (response.data.status.success){
				commit(types.LOGIN_SUCCESS, response.data)
				dispatch('pnotify/alert', {
			        text: `Welcome, ${response.data.data.user.first_name} ${response.data.data.user.last_name}.`,
			        addclass: 'bg-primary border-primary'
			    }, {root:true});
				dispatch('user/SET_USER', response.data.data.user, {root:true})
				return Promise.resolve(response.data)
			}else{
				dispatch('pnotify/alert', {
			        text: response.data.status.message,
			        addclass: 'bg-danger border-danger'
			    }, {root:true});
				commit(types.LOGIN_FAILED, {})
				dispatch('user/SET_USER', null, {root:true})
				return Promise.reject(response.data)
			}

		} catch (e) {
			console.group('[Vuex][Actions] Error from signin')
			console.log(e)
			console.groupEnd()
			commit(types.LOGIN_FAILED, {})
			dispatch('user/SET_USER', null, {root:true})
			//return Promise.reject(e)
		}
	},

	async passwordreset({ commit, dispatch }, context) {
		try {
			const response = await authAPI.passwordreset(context)
			if (response.data.status.success){
				dispatch('pnotify/alert', {
			        text: response.data.status.message,
			        addclass: 'bg-primary border-primary'
			    }, {root:true});
				return Promise.resolve(response.data)
			}else{
				dispatch('pnotify/alert', {
			        text: response.data.status.message,
			        addclass: 'bg-warning border-warning'
			    }, {root:true});
				return Promise.reject(response.data)
			}
			return Promise.reject(response.data)
		} catch (e) {
			console.group('[Vuex][Actions] Error Password request')
			console.log(e)
			console.log(e.response)
			console.groupEnd()
			dispatch('pnotify/alert', {
		        text: 'There was a problem with your request',
		        addclass: 'bg-primary border-primary'
		    }, {root:true});
			return Promise.reject(e.response)
		}
	},
	async passwordchange({ commit, dispatch }, context) {
		try {
			const response = await authAPI.passwordchange(context)
			if (response.data.status.success){
				dispatch('pnotify/alert', {
			        text: response.data.status.message,
			        addclass: 'bg-primary border-primary'
			    }, {root:true});
				return Promise.resolve(response.data)
			}else{
				dispatch('pnotify/alert', {
			        text: response.data.status.message,
			        addclass: 'bg-warning border-warning'
			    }, {root:true});
				return Promise.reject(response.data)
			}
			return Promise.reject(response.data)
		} catch (e) {
			console.group('[Vuex][Actions] Error Password request')
			console.log(e)
			console.log(e.response)
			console.groupEnd()
			dispatch('pnotify/alert', {
		        text: 'There was a problem with your request',
		        addclass: 'bg-primary border-primary'
		    }, {root:true});
		}
	},

	async me({ commit, dispatch }, context) {
		try {
			const response = await authAPI.me(context)
			if (response.data.status.success){
				dispatch('user/SET_USER', response.data.data.user, {root:true})
				return Promise.resolve(response.data)
			}else{
				commit(types.LOGIN_FAILED)
				dispatch('user/SET_USER', null, {root:true})
				return Promise.reject(response.data)
			}
		} catch (e) {
			console.group('[Vuex][Actions] Error from get user authentificated')
			console.log(e)
			console.log(e.response)
			console.groupEnd()
			commit(types.LOGIN_FAILED, e.response)
			dispatch('user/SET_USER', null, {root:true})
			return Promise.reject(e.response)
		}
	},
	logout({ commit, dispatch }) {

		commit(types.LOGIN_FAILED)
		dispatch('user/SET_USER', null, {root:true})
	}
}
const mutations = {
	[types.LOGIN_SUCCESS](state, data) {
		state.accessToken = data.data.token
		state.isAuthorize = true
		localStorage.setItem('accessToken', data.data.token)
		localStorage.setItem('isAuthorize', true)
	},
	[types.LOGIN_FAILED](state, data) {
		state.accessToken = ''
		state.isAuthorize = false
		localStorage.removeItem('accessToken')
		localStorage.removeItem('isAuthorize')
	}
}

/**
 * Export
 */
export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
