<template>
	<div class="content-wrapper">
		<pageheader title="Add new user"></pageheader>
		<div class="content">
			<div v-if="isLoaded" class="row">
        <div class="col-md-12">
					<usersadd @user:added="onAdded" @user:canceled="onCanceled"/>
				</div>
			</div>
			<div v-else="isLoaded" >
				<div style="height: 100px">
					<div class="loader loader-inline no-margin-top mt-5"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import pageheader from '@components/layout/header/index'
import usersadd from '@components/pages/users/add.vue'

export default {
	name: 'UsersAdd',
	components : {
		pageheader,
		usersadd
	},
	data() {
		return {
			isLoaded: true,
			isLoading : false
		}
	},
	mounted(){
		this.initialize();
	},
	computed: {
	},
	methods: {
		initialize() {
        },
        onAdded: function(){
        	this.$router.push({name : 'users-view'});
        },
        onCanceled: function(){
        	this.$router.push({name : 'users-view'});
        }
	}
}
</script>
