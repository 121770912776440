/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    window.moment =  require('moment-timezone');
    //require('moment-timezone'); 
    //import moment, * as moments from 'moment';
    require('bootstrap');
} catch (e) {}

require('./plugins/ui/perfect_scrollbar.min.js');
require('./plugins/forms/selects/select2.min.js');
window.PNotify = require('./plugins/notifications/pnotify.min.js');
require('./plugins/editors/summernote/summernote.min.js');
//require('./plugins/notifications/sweet_alert.min.js');
require('./plugins/forms/styling/uniform.min.js');
require('./plugins/forms/styling/switchery.min.js');
require('./plugins/pickers/daterangepicker.js');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['Accept'] = 'application/json';
window.axios.defaults.headers.common['Content-Type'] = 'application/json; charset=UTF-8';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

/** don`t need csfr token
 let token = document.head.querySelector('meta[name="csrf-token"]');

 if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}
 */

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import swal from 'sweetalert2';

const swalWithBootstrapButtons = swal.mixin({
    customClass: {
        confirmButton: 'btn btn-success m-1',
        cancelButton: 'btn btn-danger m-1',
    },
    buttonsStyling: false
})

window.Swal = swalWithBootstrapButtons;

// import Echo from 'laravel-echo'

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });
