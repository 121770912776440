<template>
  <cardBody class="p-0">
    <div class="card bg-transparent m-0 p-0 border-0 shadow-0">
      <cardBody class="pb-0 pl-0 pr-0 pt-1">
        <advancesearch v-model="search" :statuses="statusoptions" :roles="roles" :filters="filters" placeholder="Search here..."
                       :allowAdvanced="false" :isAdvancedFilled="isAdvancedFilled" @status:changed="statusChanged" @role:changed="roleChanged">
          <div class="row">
            <div class="col-md-6">
              <daterangepicker v-model="created" :label-in-placeholder="true" label="Signup"/>
            </div>
          </div>
        </advancesearch>
      </cardBody>
      <cardBody class="card-footer text-grey-300 border-0 d-sm-flex justify-content-sm-between align-items-sm-center bg-light d-flex">
        <div>
          <div v-if="! loading.status">
            {{ meta.total }} {{ meta.total != 1 ? 'accounts' : 'account' }}
            <span v-if="hasAnyFilter">
                            using filters. <a href="javascript:void(0)" @click="clearFilters">Clear filters</a>
                        </span>
          </div>
        </div>
        <div class="d-flex">
          <div class="pr-2 mt-1"># per page :</div>
          <select2 v-model="perpage" :options="per_page_options" placeholder="Par page" :hideSearchBox="true"
                   :allowClear="false" class="pb-0 mb-0 ml-auto"/>
        </div>
      </cardBody>
    </div>

  </cardBody>
</template>
<script>

import uiCard from '@components/layout/card'
import ui from '@components/ui'
import advancesearch from './advancesearch/search.vue'

export default {
  components: {
    'cardBody': uiCard.cardBody,
    'search': ui.search,
    'select2': ui.select2,
    'advancesearch': advancesearch,
    'daterangepicker': ui.daterangepicker
  },
  props: {
    meta: {
      type: Object,
      required: true
    },
    roles: {
      type: Array,
      required: true
    },
    loading: {
      type: Object,
      default: {
        status: false
      }
    }
  },
  watch: {
    optionsData() {
      this.initialize()
    },
    filtersData() {
      this.initialize()
    },
    value(v) {
      this.search = v
    },
    status(v) {
      this.filterschanged()
    },
    role(v) {
      this.filterschanged()
    },
    search(v) {
      this.filterschanged()
    },
    created(v) {
      this.filterschanged()
    },
  },
  data() {
    return {
      filters: this.$store.getters['users/filters'],
      options: this.$store.getters['users/options']
    }
  },

  mounted() {
    this.initialize();
  },
  computed: {
    filtersData: function () {
      return this.$store.getters['users/filters']
    },

    optionsData: function () {
      return this.$store.getters['users/options']
    },
    isAdvancedFilled: function () {
      return !!this.filters.created.from || !!this.filters.created.to
    },
    hasAnyFilter: function () {
      return this.isAdvancedFilled || !!this.filters.search || !!this.filters.status || !!this.filters.role
    },
    store: function () {
      return this.$store.getters
    },
    per_page_options: function () {
      return this.$store.getters['page/per_page_options'];
    },
    statusoptions: function () {
      return [
        {id: 1, name: "Active"},
        {id: 0, name: "Suspended"},
      ];
    },

    search: {
      get: function () {
        return this.filters.search
      },
      set: function (value) {
        this.filters.search = value;
      }
    },
    created: {
      get: function () {
        return this.filters.created
      },
      set: function (value) {
        this.filters.created = {
          to: value.to,
          from: value.from
        };
      }
    },
    status: {
      get: function () {
        return this.filters.status
      },
      set: function (value) {
        this.filters.status = value;
      }
    },
    role: {
      get: function () {
        return this.filters.role
      },
      set: function (value) {
        this.filters.role = value;
      }
    },

    perpage: {
      get: function () {
        return this.options.per_page || 10
      },
      set: function (value) {
        this.options.per_page = value;
        this.optionschanged()
      }
    }
  },
  methods: {
    initialize: function () {
      this.filters = {...this.filtersData}
      this.options = {...this.optionsData}
    },
    filterschanged: function () {
      this.$store.dispatch('users/setFilters', this.filters)
    },
    optionschanged: function () {
      this.$store.dispatch('users/setOptions', this.options)
    },

    clearFilters: function () {
      this.$store.dispatch('users/resetFilters')
    },
    statusChanged: function (status) {
      this.status = status
    },
    roleChanged: function (role) {
      this.role = role
    },

  }
};
</script>
