import customHelpers from './global.js'
import { helpers } from '@vuelidate/validators'

export function createHelpers(app) {
    app.config.globalProperties.$helpers = customHelpers;
}

export function customRequired(required, message = 'This field is required') {
    return helpers.withMessage(message, required);
}
