//import PNotify from 'pnotify/dist/es/PNotify';
//PNotify.defaults.delay = 3000;
//console.log(PNotify.styling);
/**
 * Import Dependency
 */

/**
 * Import API
 */

/**
 * Declare Variable
 */

/**
 * Export
 */
const state = {

}

const getters = {

}

const actions = {
	alert({ commit }, options) {
		new PNotify(options);
	},
	warning({ commit }, options) {
		new PNotify(options);
	}
}

const mutations = {
}

/**
 * Export
 */
export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}