import View from './View.vue';
import Add from './Add.vue';
import Edit from './Edit.vue';



export default {
	View,
	Add,
	Edit
}
