/**
 * Import Dependency
 */
import { createStore } from 'vuex';

import VuexPersist from 'vuex-persist'
const vuexPersist = new VuexPersist({
    key: 'pfk-states',
	storage: window.sessionStorage,
	modules:['page','user', 'users', 'participants']
})


/**
 * Root Scope of VUEX
 */
import * as getters from './getters'
import { state, actions, mutations } from './root'

/**
 * Module Scope of VUEX
 */
import page from './modules/page'
import auth from './modules/auth'
import user from './modules/user'
import pnotify from './modules/pnotify'

import dashboard from './modules/app/dashboard'
import users from './modules/app/users'
import heinz from './modules/app/heinz'

/**
 * Export
 */


export const store = createStore({
	state,
	actions,
	mutations,
	getters,
	modules: {
		page,
		auth,
		user,
		pnotify,

		dashboard,
		users,
		heinz
	},
	strict: true,
    plugins: [vuexPersist.plugin]
})
