<template>
	<div class="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
		<div class="d-flex">
			<div class="breadcrumb">
				<breadcrumbItem v-for="(breadcrumb, idx) in breadcrumbsWithLabel"  :key="idx" :index="idx" @click="routeTo(idx)" :breadcrumb="breadcrumb" :class="{'linked': !!breadcrumb.link}"></breadcrumbItem>

			</div>
		</div>
	</div>
</template>

<script>
import breadcrumbItem from './item.vue'
export default {
	name: 'Breadcrumb',
	components : {
		breadcrumbItem
	},
	data () {
		return {
			breadcrumbList: []
		}
	},
	computed : {
		breadcrumbs:  function(){
			var list = [];
			this.breadcrumbList.forEach(function(item){
				if(typeof item !== "undefined")
					item.forEach(function(bg){
						if(typeof bg !== "undefined") list.push(bg);
					});

			});
			return list;
		},
    breadcrumbsWithLabel: function() {
      return this.breadcrumbs.filter((b) => !!b.name)
    }
	},
	mounted () {
		this.updateList()
	},
	watch: {
		'$route' () {
			this.updateList()
		}
	},
	methods: {
		routeTo (pRouteTo) {
			if (this.breadcrumbList[pRouteTo].link) this.$router.push(this.breadcrumbList[pRouteTo].link)
		},
		updateList () {
			this.breadcrumbList  = this.$route.matched.map(function(r, x, v) {

				if (r.hasOwnProperty('meta')){
					if (r.meta.hasOwnProperty('breadcrumb')){
						return r.meta.breadcrumb;
					}
				}
		    });
		}
	}
}
</script>
