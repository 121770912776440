<template>
  <validation class="form-group" :validator="v" :name="name" :inputclass="inputclass">
    <label v-if="label != ''">{{ label }} <sup v-if="isRequired" class="text-danger">*</sup></label>
    <div class="input-group">
      <datepicker v-model="data" type="text" class="form-control"
                  v-bind:class="[ (v.$error || verr[0]) ? 'border-danger' : '' , true ? inputclass : '' ]"
                  :min-date="minDate" :max-date="maxDate"
                  :placeholder="placeholder"/>
      <button v-if="data != ''"
              class="btn bg-white border border-gray border-left-0  no-border-left rounded-left-0 input-group-text" type="button"
              @click="clearDate"><i class="icon-cross"></i></button>
    </div>
    <div v-if="verr[0]" class="form-text errors text-danger">{{ verr[0] }}</div>
  </validation>
</template>

<script>
import datepicker from './daterangepicker/datepicker.vue'

export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  components: {
    datepicker
  },
  props: {
    modelValue: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    inputclass: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    v: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    verr: {
      type: Array,
      default: []
    },
    minDate: {
      required: false,
      default: false
    },
    maxDate: {
      required: false,
      default: false
    }
  },
  computed: {
    data: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.v.$touch();
        this.$emit("update:modelValue", value);
      }
    }
  },
  methods: {
    clearDate: function () {
      this.data = ''
    },
  }
};
</script>
