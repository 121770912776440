/**
 * Import Dependency
 */

/**
 * Import API
 */

/**
 * Declare Variable
 */

/**
 * Export
 */
const state = {
	user: null
}

const getters = {
	user: state => state.user,

	isLoaded: state => {
		if (state.user === null) return false;
		return true;
	},
	isManagementSet: (state , getters) => {
		if (!getters.isLoaded) return false;
		if (!state.user.hasOwnProperty('management')) return false;
		return true;
	},
}

const actions = {
	SET_USER({ commit }, user) {
		commit('SET_USER', user)
	}
}

const mutations = {
	SET_USER(state, user) {
		state.user = user
	},
}

/**
 * Export
 */
export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}