<template>
  <validation class="form-group" :validator="v" :name="name" :inputclass="inputclass">
    <label v-if="label != ''">{{ label }}</label>
    <div class="mb-2">
      <a href="javascript:void(0)" :class="{'text-muted': isDisabled}" @click="selectAll" v-if="showSelectAll">Select
        all</a><span v-if="showSelectAll && showDeselectAll"> | </span><a href="javascript:void(0)" :class="{'text-muted': isDisabled}" v-if="showDeselectAll" @click="deselectAll">Deselect all</a>
    </div>

    <div class="row" v-if="initialized">
      <div class="col" :class="{'col-md-12': !twoColumns, 'col-md-6': twoColumns}" v-for="element in options" :key="element.id">
        <uiformswitch v-model="selectionList[element.id].status" :isLoading="isLoading"
                      :size="'large'"
                      :disabled="isDisabled"
                      :label="element.name" class="mb-2" :verr="[]"/>
      </div>
    </div>

    <div v-if="verr[0]" class="form-text errors text-danger">{{ verr[0] }}</div>
  </validation>
</template>

<script>

import uiformswitch from './switch'

export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  components: {
    uiformswitch
  },
  props: {
    modelValue: {
      type: Array,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    options: {
      type: Array,
      default: []
    },
    inputclass: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    v: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    showSelectAll: {
      type: Boolean,
      default: true
    },
    showDeselectAll: {
      type: Boolean,
      default: true
    },
    twoColumns: {
      type: Boolean,
      default: false
    },
    verr: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      initialized: false,
      selectionList: {}
    }
  },
  watch: {
    selectionList: {
      handler : function(value){
        this.selection = this.options.filter((el) => this.selectionList[el.id].status);
      },
      deep: true
    },
  },
  computed: {
    selection: {
      get() {
        return this.modelValue;
      },
      set(value) {
        if (this.initialized) {
          this.v.$touch();
        }
        this.$emit("update:modelValue", value);
      }
    }
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.options.forEach(function (c) {
        this.selectionList[c.id] = {
          status: this.selection ? !!this.selection.find((e) => e.id === c.id) : false,
          id: c.id
        };
      }, this);
      setTimeout(() => {
        this.initialized = true;
      })
    },
    selectAll: function () {
      if (!this.isDisabled) {
        this.options.forEach(function (c) {
          this.selectionList[c.id].status = true;
        }, this);
      }
    },
    deselectAll: function () {
      if (!this.isDisabled) {
        this.options.forEach(function (c) {
          this.selectionList[c.id].status = false;
        }, this);
      }
    }
  }
};
</script>
