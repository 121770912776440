<template>
  <div class="form-group" :inputclass="inputclass">
    <label v-if="label != ''">{{ label }}</label>
    <input type="date" :disabled='isLoading' @keyup="search" @change="search" v-model.trim="data" class="form-control"
           :placeholder="placeholder">
  </div>
</template>

<script>

export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  name: "input-date",
  props: {
    modelValue: {
      type: String,
      default: ""
    },
    inputclass: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    isLoading: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      timeoutSearch: null
    }
  },
  watch: {},
  computed: {
    data: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      }
    }
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize: function () {
      this.data = this.modelValue;
    },
    search: function () {
      clearTimeout(this.timeoutSearch);
      this.timeoutSearch = setTimeout(function () {
        this.$emit("update:modelValue", this.data);
      }.bind(this), 400);
    },
  }

};
</script>
