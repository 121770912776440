<template>
  <div class="form-group" :inputclass="inputclass">
    <label v-if="label != ''">{{ label }}</label>
    <select2 v-model="data" :options="options" :defaultText="placeholder" :hideSearchBox="hideSearchBox"
             :allowClear="allowClear"></select2>
  </div>
</template>

<script>

import select2 from "./helpers/multipleselect2.vue";

export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  components: {
    select2
  },
  props: {
    modelValue: {
      default: ""
    },
    options: {
      type: Array,
      default: ""
    },
    inputclass: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    hideSearchBox: {
      type: Boolean,
      default: false
    },
    allowClear: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    isLoading(value, old) {

    },
    modelValue(value, old) {

    },
  },
  mounted() {
    this.initialize();
  },
  computed: {
    data: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      }
    }
  },
  methods: {
    initialize() {
    },
  }
};
</script>
