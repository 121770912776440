<template>
  <div class="form-group">
    <label v-if="!!label && !labelInPlaceholder">{{ label }}</label>
    <div class="input-group">

      <datepicker v-model="from" type="text" class="form-control" v-bind:class="[ !!from ? 'border-right-0' : '']"
                  :placeholder="fromPlaceholder"/>
      <button v-if="!!from" class="btn bg-white border border-gray border-left-0 rounded-0 input-group-text" type="button"
              @click="clearFrom"><i class="icon-cross"></i></button>

      <datepicker v-model="to" type="text" class="form-control" v-bind:class="[ !!to ? 'border-right-0' : '']"
                  :placeholder="toPlaceholder"/>
      <button v-if="!!to" class="btn bg-white border border-gray border-left-0 rounded-0 input-group-text" type="button"
              @click="clearTo"><i class="icon-cross"></i></button>

      <div class="input-group-append">
        <datepickerrange v-model="value"/>
      </div>
    </div>
  </div>
</template>

<script>

import datepicker from './datepicker.vue'
import datepickerrange from './datepickerrange.vue'

export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  components: {
    datepicker,
    datepickerrange
  },
  props: {
    modelValue: {
      default: function () {
        return {
          from: '',
          to: ''
        }
      }
    },
    label: {
      type: String,
      default: ""
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    hideSearchBox: {
      type: Boolean,
      default: false
    },
    labelInPlaceholder: {
      type: Boolean,
      default: false
    },
    allowClear: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    value(value, old) {
      this.$emit("update:modelValue", value)
    },
    modelValue(value, old) {
      //this.initialize();
    }
  },
  mounted() {
    this.initialize();
  },
  computed: {
    from: {
      get() {
        return !this.value ? '' : this.value.from;
      },
      set(value) {
        if (this.value) {
          this.value.from = value;
          this.rangechanged();
        }
      }
    },
    to: {
      get() {
        return !this.value ? '' : this.value.to;
      },
      set(value) {
        if (this.value) {
          this.value.to = value;
          this.rangechanged();
        }
      }
    },
    valueData: {
      get() {
        return !this.value ? this.value : {
          from: '',
          to: ''
        };
      },
      set(value) {
        this.value = value;
        this.rangechanged();
      }
    },
    fromPlaceholder() {
      if (!this.labelInPlaceholder) return 'From';
      return this.label + ' From';
    },
    toPlaceholder() {
      if (!this.labelInPlaceholder) return 'To';
      return this.label + ' To';
    },
  },
  data() {
    return {
      value: {
        to: '',
        from: ''
      }
    }
  },
  methods: {
    initialize() {
      this.value = this.modelValue ? this.modelValue : {
        to: '',
        from: ''
      };
    },
    rangechanged: function () {
      this.$emit("update:modelValue", this.value)
    },
    clearFrom: function () {
      this.from = ''
    },
    clearTo: function () {
      this.to = ''
    }
  }
};
</script>
