<template>
	<button class="btn" v-on:click="buttonClick" :disabled='disabled' v-bind:class="[ true ? useClass : '']">
		{{buttonText}}
		<slot></slot>
	</button>
</template>
<script>

export default {
	props : {
		isLoading : {
			type: Boolean,
      		default: false
		},
		isDisabled : {
			type: Boolean,
      		default: false
		},
		text : {
			type: String,
      		default: 'Click me'
		},
		loadingText : {
			type: String,
      		default: 'Loading ...'
		},
		useClass : {
			type: String,
      		default: 'btn-primary'
		},
	},
	computed : {
		disabled (){
			return this.isLoading || this.isDisabled
		},
		buttonText (){
			if (this.isLoading) return this.loadingText;
			return this.text
		}
	},
	methods : {
		buttonClick(){
			this.$emit('click')
		}
	}

}
</script>