<template>
  <validation class="form-group" :validator="v" :name="name" :inputclass="inputclass">
    <label v-if="label != ''">{{ label }}</label>
    <textarea ref="editablefield" id="summernote" :disabled='isLoading' v-model.trim="data" class="form-control"
              v-bind:class="[ (v.$error || verr[0]) ? 'border-danger' : '' , true ? inputclass : '' ]"
              :placeholder="placeholder">{{ data }}</textarea>
    <div v-if="verr[0]" class="form-text text-danger">{{ verr[0] }}</div>
  </validation>
</template>

<script>

export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  props: {
    modelValue: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    inputclass: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    v: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    verr: {
      type: Array,
      default: []
    },
    useDragAndDrop: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    isLoading(value, old) {
      $(self.$refs.editablefield).summernote(value ? 'disable' : 'enable')
    }
  },
  mounted() {
    this.initialize();
  },

  computed: {
    data: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.v.$touch();
        this.$emit("update:modelValue", value);
      }
    }
  },
  methods: {
    initialize() {
      this.setEditableField();
    },
    setEditableField() {

      self = this;
      var config = {};
      config.minHeight = null;
      config.disableDragAndDrop = !this.useDragAndDrop
      config.focus = false;
      config.maxHeight = null;
      config.toolbar = [
        ['style', ['style', 'bold', 'italic', 'underline', 'strikethrough', 'clear']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['insert', ['link']],
        ['hh', ['undo', 'redo', 'codeview']],
      ];
      config.callbacks = {};

      config.callbacks.onInit = function () {
        $(self.$refs.editablefield).summernote("code", self.modelValue);
      }
      config.callbacks.onKeyup = function (e) {
        if ($(self.$refs.editablefield).summernote('isEmpty')) {
          self.data = '';
          return;
        }
        self.data = $(self.$refs.editablefield).summernote('code');
      };
      config.callbacks.onChange = function (content) {
        if ($(self.$refs.editablefield).summernote('isEmpty')) {
          self.data = '';
          return;
        }
        self.data = $(self.$refs.editablefield).summernote('code');
      };
      config.callbacks.onBlur = function (e) {
        if ($(self.$refs.editablefield).summernote('isEmpty')) {
          self.data = '';
          return;
        }
        self.data = $(self.$refs.editablefield).summernote('code'); // take here element code and assign
      };

      $(this.$refs.editablefield).summernote(config);

    },
  }
};
</script>
