/**
 * Import Dependency
 */
import { createRouter, createWebHistory } from 'vue-router'
import { store } from '../store'

/**
 * Import Component (.vue)
 * For Async Component Syntax
 * const X = () => import('@/pages/xxx/xxx.vue')
 */
import AppPages from '../pages'
import Application from "../../plugins/limitless";

/**
 * Declare Variable
 */


export const router = createRouter({
    history: createWebHistory(process.env.VUE_APP_PUBLIC_PATH),
    scrollBehavior: () => ({ top: 0 }),
    routes: [
        {
            path: '/auth',
            component: AppPages.Default,
            meta: {
                requiresAuth: false,
            },
            children: [
                {
                    path: 'login',
                    name: 'auth.login',
                    component: AppPages.Auth.Signin
                },
                {
                    path: 'password/reset',
                    name: 'auth.passwordreset',
                    component: AppPages.Auth.PasswordReset
                },
                {
                    path: 'password/change',
                    name: 'auth.passwordchange',
                    component: AppPages.Auth.PasswordChange,
                    props: true
                },
            ]
        },

        {
            path: '/',
            component: AppPages.App.Admin,
            name: 'home',
            redirect : 'dashboard',
            meta: {
                requiresAuth: true,
                breadcrumb: [
                 //   { name: 'Prolific' , link : 'home' }
                ]
            },
            children: [
                {
                    path: '',
                    redirect : 'dashboard'
                },
                {
                    path: 'dashboard',
                    component: AppPages.App.PageContent,
                    meta: {
                        breadcrumb: [
                            { name: 'Dashboard' , link : 'dashboard' }
                        ]
                    },
                    children: [
                        {
                            path: '',
                            name: 'dashboard',
                            component: AppPages.App.Main.Dashboard.View

                        },
                    ]
                },
                //users
                {
                    path: 'users',
                    name: 'users',
                    redirect : 'users/',
                    component: AppPages.App.PageContent,
                    meta: {
                        breadcrumb: [
                            { name: 'Users' , link : 'users-view' }
                        ]
                    },
                    children: [
                        {
                            path: '/',
                            redirect : '/users/view'
                        },
                        {
                            path: 'view',
                            name: 'users-view',
                            component: AppPages.App.Main.Users.View,
                            props: true,
                            meta: {
                                breadcrumb: [
                                    { name: '' , link : 'users-view' }
                                ]
                            },
                            children: [
                                {
                                    path: ':page',
                                    name: 'users-view-page',
                                    component: AppPages.App.Main.Users.View,
                                    meta: {
                                    },
                                }
                            ]
                        },
                        {
                            path: 'edit/:id',
                            name: 'users-edit',
                            component: AppPages.App.Main.Users.Edit,
                            props: true,
                            meta: {
                                breadcrumb: [
                                    { name: 'View' , link : 'users-edit' }
                                ]
                            },
                        },
                        {
                            path: 'add',
                            name: 'users-add',
                            component: AppPages.App.Main.Users.Add,
                            props: true,
                            meta: {
                                breadcrumb: [
                                    { name: 'Add' , link : 'users-add' }
                                ]
                            },
                        },
                    ]
                },


                //heinz
                {
                    path: 'heinz-participants',
                    name: 'heinz',
                    redirect : 'heinz-participants/',
                    component: AppPages.App.PageContent,
                    meta: {
                        breadcrumb: [
                            { name: 'Heinz' , link : 'heinz-view' }
                        ]
                    },
                    children: [
                        {
                            path: '/',
                            redirect : '/heinz/view'
                        },
                        {
                            path: 'view',
                            name: 'heinz-view',
                            component: AppPages.App.Main.Heinz.View,
                            props: true,
                            meta: {
                                breadcrumb: [
                                    { name: '' , link : 'heinz-view' }
                                ]
                            },
                            children: [
                                {
                                    path: ':page',
                                    name: 'heinz-view-page',
                                    component: AppPages.App.Main.Heinz.View,
                                    meta: {
                                    },
                                }
                            ]
                        }
                    ]
                },

            ]
        },

        {
            path: '/:pathMatch(.*)*',
            name: '404',
            component: AppPages.Errors.Error404,
            meta: {
                requiresAuth: false
            }
        }
    ]
})

/**
 * Router Guards
 */

router.beforeEach((to, from, next) => {
    Application.sidebarMobileToggle();
    if (to.path === '/auth' || to.path === '/auth/') {
        next({
            path: '/auth/login'
        })
    }

    let _accessToken = store.state.auth.accessToken || ''
    let _isAuthorize = store.state.auth.isAuthorize || false

    if (to.matched.some(record => record.meta.requiresAuth)) {

        if (_accessToken && _isAuthorize) {
            next();
        } else {
            next({
                path: '/auth/login',
                query: { redirect: to.fullPath }
            })
        }
    }else{
        next();
    }
})

function getMatchedComponents(route) {
    return route.matched.flatMap(record => Object.values(record.components))
}

// Add router hook for handling asyncData.
router.beforeResolve((to, from, next) => {
    const matched = getMatchedComponents(to)
    const prevMatched = getMatchedComponents(from)
    let diffed = false
    const activated = matched.filter((c, i) => {
        return diffed || (diffed = prevMatched[i] !== c)
    })
    const asyncDataHooks = activated.map((c) => c.asyncData).filter((_) => _)
    if (!asyncDataHooks.length) {
        return next()
    }

    bar.start()
    Promise.all(asyncDataHooks.map((hook) => hook({ store, route: to })))
        .then(() => {
            bar.finish()
            next()
        })
        .catch(next)
})

export default router
