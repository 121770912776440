<template>
	<!-- <breadcrumbs></breadcrumbs> -->
	<!--
	<div class="page-header page-header-light">
		<div class="page-header-content header-elements-md-inline">
			<div class="page-title d-flex">
				<h4>{{title}}</h4>
			</div>
			<slot>

			</slot>
		</div>
		<breadcrumbs></breadcrumbs>
	</div>
	-->
</template>

<script>

import breadcrumbs from '@components/layout/breadcrumbs'
export default {
	props: {
        title: {
            type: String,
            default: ""
        }
    },
    components : {
    	breadcrumbs
    },
    watch: {
		'$route' () {
		},
		title(){

		}
	},
}

</script>
