<template>
  <cardHeader title="Heinz Sweepstakes Entries"
              class="bg-transparent header-elements-sm-inline pr-0 pl-0 pt-4 pb-4 border-0">
    <div class="header-elements ">
      <a href="javascript:void(0)" @click="drawWinner" v-if="hasAdminRole" class="btn btn-primary rounded-pill">
        <b><i class="icon-trophy2 mr-2"></i></b> Draw Winners
      </a>

      <a href="javascript:void(0)" @click="downloadCSV" class="btn btn-primary rounded-pill ml-2">
        <b><i class="icon-download mr-2"></i></b> Download CSV
      </a>
    </div>
  </cardHeader>
</template>
<script>

import uiCard from '@components/layout/card'
import uibutton from '@components/ui/button.vue'

export default {
  components: {
    'cardHeader': uiCard.cardHeader,
    uibutton
  },
  props: {},
  watch: {},
  data() {
    return {
      isExporting: false
    }
  },

  mounted() {
  },
  computed: {
    filters: function () {
      return this.$store.getters['heinz/filters']
    },

    options: function () {
      return this.$store.getters['heinz/options']
    },
    data() {
      return {
        options: this.options,
        filters: this.filters
      }
    }
  },
  methods: {
    async drawWinner() {
      this.isExporting = true;
      this.$store.dispatch('heinz/drawWinners', {})
          .then((response) => {
            this.isExporting = false;
          });
    },
    async downloadCSV() {
      this.isExporting = true;
      this.$store.dispatch('heinz/downloadCSV', {
        filters: this.filters,
        options: null
      })
          .then((response) => {
            this.isExporting = false;
          });
    },
  }
};
</script>
