/**
 * Import Dependency
 */
import axios from '../../../interceptor'

/**
 * Declare Variable
 */
const baseUrl = process.env.VUE_APP_API_URL

const getGetUrl = `${baseUrl}/admin/dashboard`

/**
 * Export
 */
export default {
	get() {
		return axios.get(getGetUrl)
	}
}
