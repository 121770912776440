/**
 * Import Dependency
 */
import axios from '../../../interceptor'

/**
 * Declare Variable
 */
const baseUrl = process.env.VUE_APP_API_URL



const getGetUrl = `${baseUrl}/admin/users`
const getAddUrl = `${baseUrl}/admin/users/add`
const getUpdateUrl = `${baseUrl}/admin/users/update`
const getUpdatePasswordUrl = `${baseUrl}/admin/users/update-password`
const getDeleteUrl = `${baseUrl}/admin/users/delete`
const getStatusUrl = `${baseUrl}/admin/users/status`
const getGetByIdUrl = `${baseUrl}/admin/users/get`
const getMiscUrl = `${baseUrl}/admin/users/misc`

/**
 * Export
 */
export default {

	get(data) {
		return axios.post(getGetUrl, data)
	},
	misc(data) {
		return axios.get(getMiscUrl, data)
	},
	add(data) {
		return axios.post(getAddUrl, data)
	},
	update(data) {
		return axios.post(getUpdateUrl, data)
	},
	updatepassword(data) {
		return axios.post(getUpdatePasswordUrl, data)
	},
	status(data) {
		return axios.post(getStatusUrl, data)
	},
    delete(data) {
        return axios.post(getDeleteUrl, data)
    },
	getById(data) {
		return axios.post(getGetByIdUrl, data)
	}
}
