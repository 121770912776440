<template>
  <div class="content-wrapper">
    <pageheader :title="('User : ' +  (isLoaded ? elementData.name : 'Loading  ... ') )"></pageheader>
    <div class="content">
      <div v-if="isLoaded" class="row">
        <div class="col-md-12">
          <userview :element-data="elementData" @user:update="onUpdate" @user:canceled="onCanceled"/>
        </div>
      </div>
      <div v-else>
        <div style="height: 100px">
          <div class="loader loader-inline no-margin-top mt-5"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import pageheader from '@components/layout/header/index'
import ui from '@components/ui'

import userview from '@components/pages/users/edit.vue'

export default {
  name: 'UsersEdit',
  components: {
    pageheader,
    userview
  },
  props: {
    id: {
      type: [Number, String],
      default: null
    },
    element: {
      type: Object,
      default: null
    },
  },
  created() {
    if (this.id == null) {
      this.$store.dispatch('pnotify/alert', {
        text: 'Invalid user link',
        addclass: 'bg-danger border-danger'
      }, {root: true});
      //this.$router.push({ name : 'users-view' });
    }

    this.$store.dispatch('users/getById', {
      id: this.id
    })
        .then((response) => {
          this.elementData = response.data
        })
        .catch(error => {
          //this.$router.push({ name : 'users-view' });
        });

  },

  data() {
    return {
      elementData: null,
      carriers: [],
      isLoaded: false,
      isLoading: false
    }
  },
  watch: {
    hasElement(value) {
      this.isLoaded = value
    }
  },
  mounted() {
    this.initialize();
  },
  computed: {
    hasElement() {
      return this.elementData != null
    }
  },
  methods: {
    initialize() {
    },
    onUpdate: function () {
      this.$router.push({name: 'users-view'});
    },
    onCanceled: function () {
      this.$router.push({name: 'users-view'});
    }
  }
}
</script>
