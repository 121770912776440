<template>
  <div class="card-body border-0 p-0 position-relative">
    <div v-if="hasElements" class="">
      <table class="table table-hover">
        <tableHead></tableHead>
        <uielement v-for="element in elements" :key="element.id" :element="element" :loading="loading"
                   @loading:changed="loadingchanged" @element:updated="elementsUpdated"/>
      </table>

    </div>
    <div v-else-if="!isLoading" class="card-body border-0 d-flex align-items-center justify-content-center p-2" style="height:120px;">
      <div class="align-self-center text-center">
        <h6> No accounts </h6>
        <div class="text-muted">Try adjusting your filters or search term.</div>
      </div>
    </div>
    <div v-if="isLoading"
         class="position-absolute top-0 bottom-0 right-0 left-0 w-100 h-100 d-flex align-items-center justify-content-center"
         :class="{'bg-white-alpha-50': hasElements}"
    >
      <div class="loader loader-inline no-margin-top align-self-center"></div>
    </div>
  </div>
</template>

<script>
import uielement from './element.vue'
import tableHead from './tablehead.vue'

export default {
  components: {
    uielement,
    tableHead

  },
  props: {
    elements: {
      type: Array,
      default: []
    },
    meta: {
      type: Object,
      required: true
    },
    loading: {
      type: Object,
      default: {
        status: false
      }
    }
  },
  computed: {
    filters: function () {
      return this.$store.getters['heinz/filters']
    },
    options: function () {
      return this.$store.getters['heinz/options']
    },
    hasElements: function () {
      return this.elements.length > 0;
    },
    isLoading: function () {
      return this.loading.status;
    },
  },
  watch: {
    elements: {
      handler() {
      },
      deep: true
    }
  },
  data() {
    return {}
  },
  mounted() {
  },
  methods: {

    loadingchanged: function (options) {
      this.$emit('loading:changed', options)
    },
    elementsUpdated: function () {
      this.$emit('element:updated')
    },
  }
};
</script>
