<template>
  <div class="card bg-transparent border-0" v-if="isLoaded">
    <div class="card-header bg-light pl-0 pr-0 border-0">

      <div class="page-header">
        <div class="page-header-content pl-0 pr-0 header-elements-inline">
          <div class="page-title w-100 pt-2 pb-2">
            <div class="row w-100">
              <div class="col-md-6">
                <h1 class="card-title">
                  <!--<a href="javascript:void(0)" @click="cancel" ><i class="icon-arrow-left52 mr-2"></i></a> -->

                  <span class="font-weight-semibold"
                        v-if="form.first_name || form.last_name">{{ form.first_name }} {{ form.last_name }}</span>
                  <span class="font-weight-semibold" v-else>New Account</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="card-body bg-white rounded pt-4 pb-4 shadow shadow-1 shadow-sm border-0">
      <div class="row">
        <div class="col-md-6 pl-4 pr-4">

          <h4>User Details</h4>

          <uiformgrouptext :isRequired="true" v-model="v$.form.email.$model" :v="v$.form.email" :isLoading="isLoading"
                           inputclass="light-gray-bg"
                           @update:modelValue="clearError('email')"
                           name="email" label="Email" :verr="errors.email || []"/>


          <uiformgrouptext :isRequired="true" v-model="v$.form.first_name.$model" :v="v$.form.first_name"
                           @update:modelValue="clearError('first_name')"
                           :isLoading="isLoading" name="first_name" label="First Name" inputclass="light-gray-bg"
                           :verr="errors.first_name || []"/>

          <uiformgrouptext :isRequired="true" v-model="v$.form.last_name.$model" :v="v$.form.last_name"
                           @update:modelValue="clearError('last_name')"
                           :isLoading="isLoading" name="last_name" label="Last Name" inputclass="light-gray-bg"
                           :verr="errors.last_name || []"/>

          <uiformgrouppassword :isRequired="true" v-model="v$.form.password.$model" :v="v$.form.password"
                               @update:modelValue="clearError('password')"
                               :isLoading="isLoading" name="password" label="Password" inputclass="light-gray-bg"
                               :verr="errors.password || []"/>

          <uiformgrouppassword :isRequired="true" v-model="v$.form.password_confirmation.$model"
                               :v="v$.form.password_confirmation"
                               @update:modelValue="clearError('password_confirmation')"
                               :isLoading="isLoading" name="password_confirmation" label="Password Confirmation"
                               inputclass="light-gray-bg"
                               :verr="errors.password_confirmation || []"/>

        </div>
        <div class="col-md-6 pl-4 pr-4">

          <h4>Roles</h4>

          <div class="form-group">
            <label class="mb-2"><a href="javascript:void(0)" @click="selectAllRoles">Select
              all</a></label> <!--  | <a href="javascript:void(0)" @click="deselectAllRoles">Deselect all</a> -->
            <div class="p-3 light-gray-bg">
              <div class="row">
                <div class="col-md-12 my-1" v-for="role in misc.roles" :key="role.id">
                  <uiformswitch v-model="form.roles[role.id].status" :isLoading="isLoading" name="roles"
                                :size="'large'"
                                @update:modelValue="clearError('roles')"
                                :label="role.name" class="mb-2" :verr="[]"/>
                </div>
              </div>
            </div>
            <div class="errors form-text" v-if="errors.roles && errors.roles.length > 0">
              <div class="text-danger">{{ errors.roles[0] }}</div>
            </div>
          </div>

        </div>
      </div>

      <div class="row" v-if="hasAccountRole">

        <div class="col-12 pt-4 pl-4 pr-4">
          <h4>Account Holder Data</h4>
        </div>

        <div class="col-md-6 pl-4 pr-4">

          <uiformgrouptext :isRequired="true" v-model="v$.form.phone.$model" :v="v$.form.phone"
                           @update:modelValue="clearError('phone')"
                           :isLoading="isLoading" name="phone" label="Phone" inputclass="light-gray-bg"
                           :verr="errors.phone || []"/>

          <uiformgrouptext :isRequired="false" v-model="v$.form.zip.$model" :v="v$.form.zip"
                           @update:modelValue="clearError('zip')"
                           :isLoading="isLoading" name="zip" :label="zipLabel" inputclass="light-gray-bg"
                           :verr="errors.zip || []"/>

          <uiformgroupselect2
              v-model="v$.form.country_id.$model"
              :v="v$.form.country_id"
              name="country"
              :isRequired="true"
              :isLoading="isLoading"
              label="Country"
              @update:modelValue="clearError('country_id')"
              :options="misc.countries"
              :verr="errors.country_id || []"
          />

        </div>

        <div class="col-md-6 pl-4 pr-4">

          <uiformgroupselect2
              v-model="v$.form.education_level_id.$model"
              :v="v$.form.education_level_id"
              :isRequired="true"
              name="education level"
              :isLoading="isLoading"
              label="Education Level"
              @update:modelValue="clearError('education_level_id')"
              :options="misc.education_levels"
              :verr="errors.education_level_id || []"
          />

          <uiformgroupselect2
              v-model="v$.form.income_level_id.$model"
              :v="v$.form.income_level_id"
              :isRequired="true"
              name="income level"
              :isLoading="isLoading"
              label="Income Level"
              @update:modelValue="clearError('income_level_id')"
              :options="misc.income_levels"
              :verr="errors.income_level_id || []"
          />

          <uiformgroupselect2
              v-model="v$.form.people_at_home.$model"
              :v="v$.form.people_at_home"
              :isRequired="true"
              name="people at home"
              :isLoading="isLoading"
              label="People at Home"
              @update:modelValue="clearError('people_at_home')"
              :options="peopleAthHome"
              :verr="errors.people_at_home || []"
          />

          <uiformgroupselect2
              v-model="v$.form.hear_source_id.$model"
              :v="v$.form.hear_source_id"
              :isRequired="true"
              name="hear source"
              :isLoading="isLoading"
              label="How User Heard About Hartley Lab"
              @update:modelValue="clearError('hear_source_id')"
              :options="misc.hear_sources"
              :verr="errors.hear_source_id || []"
          />

        </div>

      </div>

    </div>

    <div class="clearfix mb-5"></div>

    <div class="card-body bg-light  footer-buttons">
      <uibutton text="Save" v-on:click="save" :isLoading="isLoading"
                class="btn-lg rounded-pill text-center px-4"></uibutton>
      <a href="javascript:void(0)" @click="cancel" class="ml-3 text-default">Cancel</a>
    </div>
  </div>
  <div v-else>
    <div style="height: 100px">
      <div class="loader loader-inline no-margin-top mt-5"></div>
    </div>
  </div>
</template>
<script>
import {required, alphaNum, requiredIf, email, string, minLength} from '@vuelidate/validators'
import ui from '@components/ui'
import useVuelidate from "@vuelidate/core";
import {customRequired} from "@apphelpers/index";

export default {
  name: 'UserAdd',
  setup() {
    return {v$: useVuelidate()}
  },
  components: {
    'uiformgrouptext': ui.formgrouptext,
    'uiformgrouppassword': ui.formgrouppassword,
    'uiformgrouptextarea': ui.formgrouptextarea,
    'uiformgroupWYSIWYG': ui.formgroupWYSIWYG,
    'uiformgroupswitch': ui.formgroupdoubleswitch,
    'uiformgroupselect2': ui.formgroupselect2,
    'uiformgroupprice': ui.formgroupprice,
    'uiformswitch': ui.formswitch,
    'uibutton': ui.button,
    'uiformgrouptextphone': ui.formgrouptextphone,
    'uiformgroupdatepicker': ui.formgroupdatepicker

  },
  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        roles: {},
        email: '',
        password: "",
        password_confirmation: "",
      },
      misc: null,
      errors: {},
      isLoading: false
    }
  },
  watch: {
    misc() {
    },
    isLoaded() {
    },
  },
  mounted() {
    this.initialize();
  },
  computed: {
    isLoaded() {
      return this.misc != null
    },

  },
  validations() {
    return {
      form: {
        first_name: {required: customRequired(required)},
        last_name: {required: customRequired(required)},
        roles: {required: customRequired(required)},
        email: {required: customRequired(required), email},
        password: {required: customRequired(required), minLength: minLength(6)},
        password_confirmation: {required: customRequired(required), minLength: minLength(6)},
      }
    }
  },
  methods: {
    clearError: function (field) {
      this.deleteObjProperty(this.errors, field);
    },
    selectAllRoles: function () {
      this.misc.roles.forEach(function (c) {
        this.form.roles[c.id].status = true;
      }, this);
    },
    deselectAllRoles: function () {
      this.misc.roles.forEach(function (c) {
        this.form.roles[c.id].status = false;
      }, this);
    },
    initializeRoles() {
      this.misc.roles.forEach(function (c) {
        this.form.roles[c.id] = {
          status: false,
          id: c.id
        };
      }, this);
    },

    initialize() {
      this.loadMisc();
    },

    loadMisc: function () {
      this.$store.dispatch('users/misc')
          .then((response) => {
            this.misc = response.data;
            this.initializeRoles();
          })
    },

    cancel: function () {
      this.$emit('user:canceled');
    },
    save() {
      this.v$.form.$touch();
      // if its still pending or an error is returned do not submit
      if (this.v$.form.$pending || this.v$.form.$error) {
        this.$store.dispatch('pnotify/alert', {
          text: 'Please check the errors.',
          addclass: 'bg-danger border-danger'
        }, {root: true});
        return;
      }

      this.isLoading = true;
      this.errors = {}

      this.$store.dispatch('users/add', this.form)
          .then((response) => {
            this.isLoading = false;
            this.$emit('user:added');
          })
          .catch(error => {
            this.isLoading = false;
            this.errors = error.status.errors
          });
    }
  }
}
</script>
