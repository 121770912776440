import card from './card.vue'
import cardBody from './cardBody.vue'
import cardFooter from './cardFooter.vue'
import cardHeader from './cardHeader.vue'

export default {
	card,
	cardBody,
	cardFooter,
	cardHeader
}