<template>
  <validation class="form-group-feedback form-group-feedback-left" :validator="v" :name="name">
    <input type="password" :disabled='isLoading' v-model.trim="password" class="form-control"
           v-bind:class="[ (v.$error || verr[0]) ? 'border-danger' : '' ]" :placeholder="placeholder">
    <div class="form-control-feedback">
      <i class="icon-lock2 text-muted"></i>
    </div>
    <div v-if="verr[0]" class="form-text text-danger">{{ verr[0] }}</div>
  </validation>
</template>

<script>

export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  props: {
    modelValue: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: "Password"
    },
    placeholder: {
      type: String,
      default: "Password"
    },
    verr: {
      type: Array,
      default: []
    },
    v: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    password: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.v.$touch();
        this.$emit("update:modelValue", value);
      }
    }
  }
};
</script>
