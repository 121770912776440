<template>

    <!-- Page content -->
    <div class="page-content">
        <!-- Main content -->
        <div class="content-wrapper">

            <!-- Content area -->
            <div class="content d-flex justify-content-center align-items-center">
                <!-- Advanced login -->

                    <div class="login-form">
                        <div class="card-body">
                            <div class="text-center mb-3">
                                <div class="pl-2 pr-2 mb-3 mt-1">
                                    <img src="@assets/images/logo.png" style="display: block; max-width: 100%;" />
                                </div>
                                <h5 class="mb-0">Reset Password</h5>
                                <span class="d-block text-muted">Enter your email address</span>
                            </div>

                            <uiemail v-model="form.email" :v="v$.form.email" :isLoading="isLoading" :verr="errors.email || []"/>

                            <div class="form-group">
                               <uibutton text="Send Password Reset Link" v-on:click="resetpassword" :isLoading="isLoading" class="btn-block btn-lg">
                                    <i v-if='!isLoading' class="icon-arrow-right22 ml-3"></i>
                                    <i v-if='isLoading' class="icon-spinner2 spinner ml-3"></i>
                                </uibutton>
                            </div>
                            <div class=" text-center">
                                <span class="m-t-3 help-block">If you remembered your password, go to <router-link :to="{name : 'auth.login'}">Login</router-link> to access your account.</span>
                            </div>
                        </div>
                    </div>
                <!-- /advanced login -->

            </div>
            <!-- /content area -->

        </div>
        <!-- /main content -->

    </div>
    <!-- /page content -->

</template>
<script>

import { required, email } from '@vuelidate/validators'
import  ui  from '@components/ui'
import useVuelidate from "@vuelidate/core";

    export default {
        name: 'passwordresetform',
      setup () {
        return { v$: useVuelidate() }
      },
        components : {
            'uibutton' : ui.button,
            'uiemail' : ui.email
        },
        data () {
            return {
                isLoading : false,
                errors: [],
                form: {
                    email: ""
                },
            }
        },
        watch: {
            email (value, old) {
                this.deleteObjProperty(this.errors, 'email')
            },
        },
        computed: {
            email() {
                return this.form.email
            },
        },
        validations: {
            form: {
                email: { required, email }
            }
        },
        methods: {
            resetpassword () {
                this.v$.form.$touch();
                // if its still pending or an error is returned do not submit

                if (this.v$.form.$pending || this.v$.form.$error) {
                    this.$store.dispatch('pnotify/alert', {
                        text: 'Please check the errors.',
                        addclass: 'bg-danger border-danger'
                    }, {root:true});
                    return;
                }

                this.isLoading = true;
                this.errors = []

                this.$store.dispatch('auth/passwordreset', {
                    email: this.form.email,
                })
                .then((response) => {
                    this.form.email = '';
                    this.isLoading = false;
                })
                .catch( error => {
                    this.isLoading = false;
                    this.errors = error.status.errors
                });
            }
        }
    }
</script>
