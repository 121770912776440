<template>
  <validation class="form-check form-check-switchery form-check-switchery-double " :validator="v" :name="name"
              :inputclass="inputclass">
    <div class="checkbox checkbox-switchery switchery-double switchery-xs switchery-double  mt-10">
      <label class="form-check-label" :style="switchStyle">
        {{ labelLeft }}
        <input ref="switchery" type="checkbox" v-model="data" :disabled="disabled" class="switchery">
        {{ label }}
      </label>
    </div>
    <div v-if="verr[0]" class="form-text errors text-danger">{{ verr[0] }}</div>
  </validation>
</template>

<script>


export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ""
    },
    labelLeft: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    size: {
      type: String,
      default: 'default'
    },
    inputclass: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    v: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    verr: {
      type: Array,
      default: []
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    modelValue: function (value) {
      this.setSwitchery(this.switchery, value)
    },
    disabled(value, old) {
      if (value) {
        this.switchery.disable();
      }
      else {
        this.switchery.enable();
      }
    }
  },
  data() {
    return {
      switchery: null,

    }
  },
  computed: {
    data: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.v.$touch();
        this.$emit("update:modelValue", value);
      }
    },
    switchStyle() {
      return {
        opacity: this.disabled ? '0.5' : '1'
      }
    }
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.switchery == null) {
        this.switchery = new Switchery(this.$refs.switchery, {
          disabled: this.disabled,
          secondaryColor: '#3F51B5',
          size: this.size ?? 'default'
        })
        this.setSwitchery(this.switchery, this.data)
        if (this.disabled) this.switchery.disable();
      };
    },
    setSwitchery: function (switchElement, checkedBool) {
      if ((checkedBool && !switchElement.isChecked()) || (!checkedBool && switchElement.isChecked())) {
        switchElement.setPosition(true);
        switchElement.handleOnchange(true);
      }
    }

  }
};
</script>
