<template>
  <!-- Page content -->
  <div class="page-content">

    <!-- Main content -->
    <div class="content-wrapper">

      <!-- Content area -->
      <div class="content d-flex justify-content-center align-items-center">

        <!-- Login card -->
        <div class="login-form">
          <div class="card-body">

            <div class="text-center mb-3">
              <div class="pl-2 pr-2 mb-3 mt-1">
                <img src="@assets/images/logo.png" style="display: block; max-width: 100%;" />
              </div>
              <h5 class="mb-0">Sweepstakes Tool</h5>
            </div>

            <uiemail v-model="form.email" :v="v$.form.email" :isLoading="isLoading" :verr="errors.email || []"/>
            <uipassword v-model="form.password" :v="v$.form.password" :isLoading="isLoading"
                        :verr="errors.password || []"/>

<!--            <div class="form-group d-flex align-items-center">-->
<!--              <router-link :to="{ name : 'auth.passwordreset'}" class="ml-auto">Forgot password?</router-link>-->
<!--            </div>-->

            <div class="form-group">
              <!--
                <button class="btn btn-primary btn-block" v-on:click="signin" :disabled='isLoading'>Sign in
                  <i v-if='!isLoading' class="icon-arrow-right22 ml-3"></i>
                  <i v-if='isLoading' class="icon-spinner2 spinner ml-3"></i>
                </button>
              -->
              <uibutton text="Sign in" v-on:click="signin" :isLoading="isLoading" class="btn-block btn-lg">
                <i v-if='!isLoading' class="icon-arrow-right22 ml-3"></i>
                <i v-if='isLoading' class="icon-spinner2 spinner ml-3"></i>
              </uibutton>
            </div>
          </div>
        </div>
        <!-- /login card -->

      </div>
      <!-- /content area -->
    </div>
    <!-- /main content -->

  </div>
  <!-- /page content -->
</template>

<script>

import {required, email, string} from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'

import ui from '@components/ui'

import router from '@approuter/index'

export default {
  setup() {
    return {v$: useVuelidate()}
  },
  components: {
    'uibutton': ui.button,
    'uiemail': ui.email,
    'uipassword': ui.password
  },
  name: 'Signin',
  watch: {
    email(value, old) {
      this.deleteObjProperty(this.errors, 'email')
    },
    password(value, old) {
      this.deleteObjProperty(this.errors, 'password')
    }
  },
  data() {
    return {
      form: {
        email: "",
        password: ""
      },
      errors: {},
      isLoading: false
    }
  },
  mounted() {

  },
  computed: {
    email() {
      return this.form.email
    },
    password() {
      return this.form.password
    },
    authinfo() {
      return this.$store
    },
    isAuthorize() {
      return this.$store.getters.get.auth.isAuthorize
    },
    user() {
      return this.$store.getters.get.user.user
    }
  },
  validations: {
    form: {
      email: {required, email},
      password: {required}
    }
  },
  methods: {

    signin() {
      this.v$.form.$touch();
      // if its still pending or an error is returned do not submit
      if (this.v$.form.$pending || this.v$.form.$error) {
        this.$store.dispatch('pnotify/alert', {
          text: 'Please check the errors.',
          addclass: 'bg-danger border-danger'
        }, {root: true});
        return;
      }

      this.isLoading = true;
      this.errors = []

      this.$store.dispatch('auth/signin', {
        email: this.form.email,
        password: this.form.password
      })
          .then((response) => {
            this.isLoading = false;
            this.redirectAfterAuthentification();
          })
          .catch(error => {
            this.isLoading = false;
            if (error && error.status) {
              this.errors = error.status.errors
            }
          });
    },
    redirectAfterAuthentification: function () {
      if (this.$route.query.hasOwnProperty('redirect')) {
        let routeResolved = this.$router.resolve(this.$route.query.redirect);
        if (routeResolved && routeResolved.name !== '404') {
          this.$router.push(routeResolved)
          return;
        }
      }
      this.$router.push({name: 'dashboard'});
    }
  }
}
</script>
