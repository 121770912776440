<template>
  <div class="form-group" :inputclass="inputclass">
    <label v-if="label != ''">{{ label }}</label>
    <div class="input-group">
      <input type="text" :disabled='isLoading' @keyup="search" v-model.trim="data" class="form-control "
             v-bind:class="[ (hasSearchString) ? 'border-right-0' : '']" :placeholder="placeholder">
      <span v-if="hasSearchString" class="input-group-append">
                <button class="btn btn-white border border-left-0" type="button" @click="clear"><i
                    class="icon-cross"></i></button>
            </span>
    </div>

  </div>
</template>

<script>

export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  props: {
    modelValue: {
      type: String,
      default: ""
    },
    inputclass: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    isLoading: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    modelValue(v) {
      this.data = v
    },
    data() {

    }
  },
  data() {
    return {
      timeoutSearch: null,
      data: this.modelValue
    }
  },
  computed: {
    hasSearchString: function () {
      return !(this.data == '' || this.data == null)
    }
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize: function () {
      this.data = this.modelValue;
    },
    search: function () {
      clearTimeout(this.timeoutSearch);
      this.timeoutSearch = setTimeout(function () {
        this.$emit("update:modelValue", this.data);
      }.bind(this), 400);
    },
    clear: function () {
      this.data = '';
      this.search();
    }
  }

};
</script>
