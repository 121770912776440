<template>
	<div class="card-header">
		<h1 class="card-title">{{title}}</h1>
		<slot></slot>
	</div>
</template>

<script>

export default {
	props: {
        title: {
            type: String,
            default: ""
        }
    },
}

</script>
