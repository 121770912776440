<template>
  <validation class="form-group" :validator="v" :name="name" :inputclass="inputclass">
    <label v-if="label != ''"><span v-html="label"></span> <sup v-if="isRequired" class="text-danger">*</sup></label>
    <masked-input
        type="text"
        :disabled='isLoading'
        v-model.trim="time" class="form-control"
        v-bind:class="[ (v.$error || verr[0]) ? 'border-danger' : '' , true ? inputclass : '' ]"
        :placeholder="placeholder"
        autocomplete="no"
        :mask="[ /[0-2]/, /\d/, ':', /[0-5]/, /\d/]"></masked-input>
    <div v-if="verr[0]" class="form-text errors text-danger">{{ verr[0] }}</div>
  </validation>
</template>

<script>


export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    inputclass: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    v: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    verr: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      initialized: false,
      time: ''
    }
  },
  computed: {
    data: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.v.$touch();
        this.$emit("update:modelValue", value);
      }
    }
  },
  watch: {
    time(value, old) {
      const parts = value.split(':');
      if (parts.length === 2) {
        let hours = parseInt(parts[0].replace('_',''));
        let minutes = parseInt(parts[1].replace('_',''));
        if (!hours) hours = 0;
        if (!minutes) minutes = 0;
        this.data = hours * 60 + minutes;
      }
      else this.data = "";
    }
  },
  mounted() {
    this.initialize()
  },
  methods: {
    initialize() {
      if (this.data) {
        let hours = Math.floor(parseInt(this.data) / 60);
        if (hours < 10) hours = '0' + hours;
        let minutes = Math.floor(parseInt(this.data) % 60);
        if (minutes < 10) minutes = '0'+minutes;
        const time = hours + ':' + minutes;
        if (this.time !== time) {
          this.time = hours + ':' + minutes;
        }
      }
      else {
        this.time = '';
      }
      this.initialized = true;
    }
  }
};
</script>
