<template>
  <thead>
  <tr>
    <th>ID</th>
    <th class="" @click="setOrder('last_name')"
        v-bind:class="[ this.order_by === 'last_name' ? 'fosorting_' + this.order_type : 'fosorting']">Name
    </th>
    <th class="" @click="setOrder('email')"
        v-bind:class="[ this.order_by === 'email' ? 'fosorting_' + this.order_type : 'fosorting']">Email
    </th>
    <th>Restaurant</th>
    <th>Address</th>
    <th>City</th>
    <th>State</th>
    <th>Zip</th>
    <th>Uses Heinz</th>
    <th class="" @click="setOrder('created_at')"
        v-bind:class="[ this.order_by === 'created_at' ? 'fosorting_' + this.order_type : 'fosorting']">Date
    </th>
    <th class="text-center "></th>
  </tr>
  </thead>
</template>

<script>

export default {
  components: {},
  props: {},
  watch: {
    optionsData: {
      handler: function (val, oldVal) {
        this.initialize();
      },
      deep: true
    }
  },
  data() {
    return {
      opt: this.$store.getters['heinz/options']
    }
  },

  mounted() {
    this.initialize();
  },
  computed: {
    filters: function() {
      return this.$store.getters['heinz/filters'];
    },
    optionsData: function () {
      return this.$store.getters['heinz/options']
    },
    order_by: function () {
      return this.opt.order_by
    },
    order_type: function () {
      return this.opt.order_type
    },
  },
  methods: {
    initialize() {
      this.opt = {...this.optionsData};
    },
    setOrder: function (sortby) {
      if (this.opt.order_by === sortby) {
        this.opt.order_type = this.opt.order_type === 'asc' ? 'desc' : 'asc'
      } else {
        this.opt.order_by = sortby
        this.opt.order_type = 'asc'
      }
      this.$store.dispatch('heinz/setOptions', this.opt)
    }
  }
};
</script>
