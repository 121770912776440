<template>
    <div class="navbar-brand mb-3" >
        <router-link :to="{ name : 'dashboard' }" class="d-inline-block text-white text-center w-100"
                     style="font-size: 16px; height: 2.625em; line-height: 2.625em;" >
            <img src="@assets/images/logo_light.png" class="header-normal-logo d-inline-block" style="height:20px" />
            <i class="header-mini-logo"><img src="@assets/images/logo_icon.png" class="d-inline-block" style="height:20px" /></i>
        </router-link>
    </div>
</template>
<script>

export default {
    name: 'logo',

    components : {
    },
    watch: {
    },
    data () {
        return {
        }
    },
    computed: {
    },
    beforeMount(){
    },
    mounted(){

    },
    unmounted(){

    },
    methods : {

    }


}
</script>
