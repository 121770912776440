<template>
  <validation class="form-group" :validator="v" :name="name" :inputclass="inputclass">
    <label v-if="label != ''">{{ label }}</label>
    <input type="password" :disabled='isLoading' v-model.trim="data" class="form-control"
           v-bind:class="[ (v.$error || verr[0]) ? 'border-danger' : '' , true ? inputclass : '' ]"
           :placeholder="placeholder" autocomplete="off">
    <div v-if="verr[0]" class="form-text errors text-danger">{{ verr[0] }}</div>
  </validation>
</template>

<script>


export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  props: {
    modelValue: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    inputclass: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    v: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    verr: {
      type: Array,
      default: []
    }
  },
  computed: {
    data: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.v.$touch();
        this.$emit("update:modelValue", value);
      }
    }
  }
};
</script>
