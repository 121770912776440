<template>
    <!-- Main navbar -->
    <div class="navbar navbar-expand-md navbar-light fixed-top">
        <!-- <logo></logo> -->

        <div class="d-md-none">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-mobile">
                <i class="icon-tree5"></i>
            </button>
            <button class="navbar-toggler sidebar-mobile-main-toggle" type="button">
                <i class="icon-paragraph-justify3"></i>
            </button>
        </div>

        <div class="collapse navbar-collapse" id="navbar-mobile">
            <ul class="navbar-nav">
                <sidebar></sidebar>
            </ul>

            <span class="navbar-text ml-md-3 mr-md-auto">
                <!--<span class="badge bg-success">Online</span>-->
            </span>


            <ul class="navbar-nav">
                <user></user>
            </ul>
        </div>
    </div>
    <!-- /main navbar -->

</template>
<script>

import navbarComponents from './helpers'

export default {
    name: 'navbar',
    components : {
        'user' : navbarComponents.user,
        'logo' : navbarComponents.logo,
        'sidebar' : navbarComponents.sidebar
    },
    watch: {

    },
    data () {
        return {

        }
    },
    computed: {

    },
    mounted(){
        //$('body ').addClass('navbar-top');
        this.setup();
    },
    unmounted(){
        //$('body').removeClass('navbar-top');
    },
    methods : {
        setup : function(){
            //appVueSetup();

        },
    }


}
</script>
