<template>
  <div class="main-app">
    <navbar></navbar>
    <!-- Page content -->
    <div class="page-content bg-light">
      <sidebar></sidebar>
      <router-view></router-view>
    </div>
    <!-- /page content -->
  </div>
</template>
<script>

//elements
import navbar from '@components/layout/navbar/Index.vue'
import sidebar from '@components/layout/sidebar/Index.vue'
import Application from "../../../plugins/limitless";
import FixedSidebarCustomScroll from "../../../plugins/custom";

export default {
  data() {
    return {}
  },
  components: {
    navbar, sidebar
  },
  mounted: function () {
    Application.initCore();
    FixedSidebarCustomScroll.init();
  },
  watch: {
    user(newValue, oldValue) {
      if (newValue == null) this.$router.push('/auth/login');
    }
  },
  computed: {},
}
</script>
