<template>
  <div class="form-check">
    <label class="form-check-label">
      <input ref="inputradio" type="radio" :id="'radio'+data" v-model="valueData" :value="data"
             class="form-check-input-styled" :name="name">
      {{ label }}
    </label>
  </div>
</template>
<script>
import radio from './radio.vue'

export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  components: {
    radio
  },
  props: {
    modelValue: {
      required: true
    },
    name: {
      required: true
    },
    data: {
      required: true
    },
    disabled: {
      type: [Boolean],
      default: false
    },
    label: {
      type: String,
      default: ""
    }
  },
  watch: {
    valueData: function (value) {
      //console.log('fac update in radiouniform', value);
      this.$emit("update:modelValue", value)
    },
    data: function (value) {
    }
  },
  data() {
    return {
      valueData: '',
      loaded: false
    }
  },

  mounted() {
    this.initialize()
  },
  computed: {
    isLoaded() {
      return this.loaded
    }
  },
  methods: {
    initialize: function () {
      this.valueData = this.modelValue;
      this.loaded = true;
      $(this.$refs.inputradio).uniform();
    },
  }
}
</script>
