<template>
  <tr>
    <td>{{element.id}}</td>
    <td :class="{'text-danger': element.is_disqualified}">
      <i class="iconx-suspend text-danger mr-1" v-if="element.is_disqualified"></i>
      <!-- <i class="iconx-unsuspend text-success mr-1" v-if="!element.is_disqualified"></i> -->
      {{ element.first_name }} {{ element.last_name }}
    </td>
    <td>{{ element.email }}</td>
    <td>{{ element.restaurant }}</td>
    <td>{{ element.restaurant_address }}</td>
    <td>{{ element.restaurant_city }}</td>
    <td>{{ element.restaurant_state }}</td>
    <td>{{ element.zip }}</td>
    <td>{{ element.use_heinz ? 'Yes' : 'No' }}</td>
    <td>{{ element.created_at ? momentTZ(element.created_at) : '-' }}</td>
    <td class="text-center">
      <div class="list-icons">
        <div class="dropdown">
          <a href="#" class="list-icons-item dropdown-toggle caret-0" data-toggle="dropdown" aria-expanded="false">
            <i class="icon-more2 rotate-90"></i></a>
          <div class="dropdown-menu dropdown-menu-right" x-placement="bottom-end">
            <a v-if="!element.is_disqualified" href="javascript:void(0)" @click="disqualify" class="dropdown-item">
              <i class="iconx-suspend text-danger"></i> Disqualify </a>
            <a v-if="element.is_disqualified" href="javascript:void(0)" @click="qualify" class="dropdown-item">
              <i class="iconx-unsuspend text-success"></i> Make Valid </a>
            <a v-if="canDelete" href="javascript:void(0)" class="dropdown-item" @click="deleteElementConfirmation"><i
                class="iconx-delete text-danger"></i> Delete </a>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>


export default {
  components: {

  },
  props: {
    element: {
      type: Object,
      required: true
    },
    loading: {
      type: Object,
      default: function () {
        return {
          status: false
        }
      }
    }
  },
  computed: {
    filters: function() {
      return this.$store.getters['heinz/filters'];
    },
    hasValues() {
      return this.element.values.length > 0
    },
    elementRoles() {
      return this.element.roles.map(x => x.name)
    },
    canSuspend() {
      return this.element.id !== this.loggedUser.id;
    },
    canDelete() {
      return this.element.id !== this.loggedUser.id;
    },
  },
  methods: {

    qualify: function () {
      this.saveStatus({
        id: this.element.id,
        status: false
      });
    },

    disqualify: function () {
      this.saveStatus({
        id: this.element.id,
        status: true
      });
    },

    saveStatus: function (data) {
      this.loading.status = true;
      this.$store.dispatch('heinz/status', data)
          .then((response) => {
            this.loading.status = false;
            this.$emit('element:updated');

            this.$swal({
              title: "Updated!",
              text: "Entry has been " + (data.status ? 'disqualified' : 'validated') + ".",
              iconHtml: '<i class="iconx-verified"></i>',
              customClass: {
                confirmButton: 'btn btn-lg btn-success rounded-pill wmin-100'
              },
              icon: "success"
            });

          })
          .catch(error => {
            this.loading.status = false;
            this.errors = error.status.errors
          });
    },

    deleteElementConfirmation: function () {
      self = this;
      this.$swal({
        title: "Are you sure?",
        text: "You will not be able to recover this entry!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete!",
        cancelButtonText: "No, cancel!",
        iconHtml: '<i class="iconx-alert"></i>',
        customClass: {
          confirmButton: 'btn btn-lg btn-success rounded-pill wmin-100',
          cancelButton: 'btn btn-lg btn-danger rounded-pill wmin-100 ml-3'
        },

      }).then((isConfirm) => {
        if (isConfirm.value) {
          self.deleteElement();
        }
      });
    },

    deleteElement: function () {
      this.loading.status = true;
      this.$store.dispatch('heinz/delete', {id: this.element.id})
          .then((response) => {
            this.loading.status = false;
            this.$emit('element:updated');
            if (response.status.success) {
              this.$swal({
                title: "Deleted!",
                text: "Entry has been deleted.",
                iconHtml: '<i class="iconx-verified"></i>',
                customClass: {
                  confirmButton: 'btn btn-lg btn-success rounded-pill wmin-100'
                },
                icon: "success"
              });
            }

          })
          .catch((error) => {
            this.loading.status = false;

            this.$store.dispatch('pnotify/alert', {
              text: error.status.message,
              addclass: 'bg-danger border-danger'
            });


          })
    }

  }
};
</script>
